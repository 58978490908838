import { createStore } from 'vuex'
import security from "./security";

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    security
  }
})