/* eslint-disable *
<template>
  <nav
    id="navbar"
    class="sticky top-0 z-50 bg-indigo-700"
    role="navigation"
    aria-label="navigation"
  >
    <div
      class="px-5 py-5 mx-auto md:flex"
    >
      <div class="flex">
        <!-- Mobile menu button -->
        <div
          class="flex lg:hidden"
          @click="toggleNav"
        >
          <button
            type="button"
            :aria-label="!isEnglish() ? 'Menü öffnen / schließen' : 'open / hide menu'"
            class="
            flex
              text-gray-100
              hover:text-gray-400
              focus:outline-none focus:text-gray-400
            "
          >
            <svg
              viewBox="0 0 24 24"
              class="w-8 h-8 fill-current"
            >
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              />
            </svg>
          </button>
        </div>
      </div>
      <!-- Mobile menu button - END -->

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="
          flex-col
          mt-8
          space-y-4
          lg:flex lg:space-y-0 lg:flex-row lg:items-center sm:space-x-1 lg:space-x-6 xl:space-x-20 lg:mt-0
          text-xl
          font-bold
          text-gray-100
          md:text-2xl
          hover:text-gray-100
        "
      >
        <li>
          <router-link
            to="/"
            class="text-xl
              font-bold
              md:text-2xl"
          >
            <h1 class="text-white">
              <img
                :src="logo"
                alt="Logo Demoalarm"
                class="h-12"
                height="42"
                width="42"
              >
            </h1>
          </router-link>
        </li>
        <li>
          <router-link
            to="/"
            tabindex="0"
            class="pb-1"
            @click="toggleNav(); reloadPage();"
          >
            <span class="text-white hover">
              <i class="home icon" />
              {{ isEnglish() ? 'Demos' : 'Demos' }}
            </span>
          </router-link>
        </li>
        <li
          v-if="!isAuthenticated"
        >
          <router-link
            to="/login"
            class="pb-1"
            tabindex="0"
            @click="toggleNav"
          >
            <span class="text-white hover">
              <i class="sign-in icon" />
              {{ isEnglish() ? 'Login' : 'Login' }}
            </span>
          </router-link>
        </li>
        <li
          v-if="isAuthenticated"
        >
          <a
            class="pb-1"
            onclick="(function(){ document.cookie.split(&quot;;&quot;).forEach(function(c) { document.cookie = c.replace(/^ +/, &quot;&quot;).replace(/=.*/, &quot;=;expires=&quot; + new Date().toUTCString() + &quot;;path=/&quot;);}); })();  location.reload();"
            href="javascript:void(0);"
          >
            <span class="text-white hover">
              <i class="sign-out icon" />
              {{ isEnglish() ? 'Logout' : 'Abmelden' }}
            </span>
          </a>
        </li>
        <li>
          <router-link
            to="/report"
            class="pb-1"
            tabindex="0"
            :aria-label="!isEnglish() ? 'Neue Demo melden' : 'Report new event'"
            @click="toggleNav"
          >
            <span class="text-white hover">
              <i class="upload icon" />
              {{ isEnglish() ? 'Report' : 'Melden' }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/imprint"
            class="pb-1"
            tabindex="0"
            @click="toggleNav"
          >
            <span class="text-white hover">
              <i class="balance scale icon" />
              {{ isEnglish() ? 'Imprint' : 'Impressum' }}
            </span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/privacy"
            class="pb-1"
            tabindex="0"
            @click="toggleNav"
          >
            <span class="text-white hover">
              <i class="user secret icon" />
              {{ isEnglish() ? 'Privacy' : 'Datenschutz' }}
            </span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="fixed right-48 lg:right-64 top-3 lg:top-5">
      <button
        :class="{ 'active': currentLanguage == 'en' }"
        class="focus hover text-white pt-1"
        type="button"
        tabindex="0"
        @click="changeLanguage('en')"
      >
        <img
          :src="englishIcon"
          class="h-10 relative"
          alt="isEnglish() 'icon for English-language- button' : 'Bild für Button zum Wechsel der Sprache auf English'"
          height="35"
          width="35"
        >
      </button>
      <button
        type="button"
        class="focus hover text-white ml-4"
        :class="{ 'active': currentLanguage == 'de' }"
        tabindex="0"
        @click="changeLanguage('de')"
      >
        <img
          :src="germanIcon"
          class="h-10 relative"
          alt="isEnglish() 'icon for German-language- button' : 'Bild für Button zum Wechsel der Sprache auf Deutsch'"
          height="35"
          width="35"
        >
      </button>
    </div>
    <div class="fixed right-28 lg:right-36 top-4 lg:top-6 ">
      <button
        class="mr-4 text-4xl hover text-white"
        :class="{ 'active': fontSize == '1.3rem' }"
        tabindex="0"
        type="button"
        @click="changeFontSize('1.3rem')"
      >
        A
      </button>
      <button
        class="text-2xl hover text-white"
        :class="{ 'active': fontSize == '1.1rem' }" 
        tabindex="0"
        height="40"
        width="40"
        type="button"
        @click="changeFontSize('1.1rem')"
      >
        A
      </button>
    </div>
    <div class="fixed right-5 top-6 lg:top-8 w-16">
      <input
        id="checkbox"
        class="hidden"
        type="checkbox"
        @change="toggleTheme"
      >
      <label
        for="checkbox"
        class="switch-label"
        tabindex="0" 
        @keyup.enter="toggleTheme"
      >
        <span class="select-none">🌙</span>
        <span class="select-none">☀️</span>
        <div
          class="switch-toggle"
          :class="{ 'switch-toggle-checked': $userTheme.value === 'dark-theme' }"
        />
      </label>
    </div>
  </nav>
  <router-view />
  <!-- notifications position="top center" / -->
  <NotificationGroup
    group="error"
  >
    <div
      class="fixed pointer-events-none z-50 top-16 right-10"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
          >
            <div class="flex items-center justify-center w-12 bg-red-900">
              <svg
                class="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.36667C10.8167 3.36667 3.3667 10.8167 3.3667 20C3.3667 29.1833 10.8167 36.6333 20 36.6333C29.1834 36.6333 36.6334 29.1833 36.6334 20C36.6334 10.8167 29.1834 3.36667 20 3.36667ZM19.1334 33.3333V22.9H13.3334L21.6667 6.66667V17.1H27.25L19.1334 33.3333Z" />
              </svg>
            </div>

            <div class="px-4 py-2 -mx-3">
              <div class="mx-3">
                <span class="font-semibold text-xl text-red-900">{{ notification.title }}</span>
                <p class="text-xl text-gray-600">
                  {{ notification.text }}
                </p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>

  <NotificationGroup group="info">
    <div
      class="fixed pointer-events-none z-50 top-16 right-10"
    >
      <div class="w-full max-w-sm">
        <Notification
          v-slot="{ notifications }"
          enter="transform ease-out duration-300 transition"
          enter-from="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4"
          enter-to="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
          move="transition duration-500"
          move-delay="delay-300"
        >
          <div
            v-for="notification in notifications"
            :key="notification.id"
            class="flex w-full max-w-sm mx-auto mt-4 overflow-hidden bg-white rounded-lg shadow-md"
          >
            <div class="flex items-center justify-center w-12 bg-blue-900">
              <svg
                class="w-6 h-6 text-white fill-current"
                viewBox="0 0 40 40"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 3.33331C10.8 3.33331 3.33337 10.8 3.33337 20C3.33337 29.2 10.8 36.6666 20 36.6666C29.2 36.6666 36.6667 29.2 36.6667 20C36.6667 10.8 29.2 3.33331 20 3.33331ZM21.6667 28.3333H18.3334V25H21.6667V28.3333ZM21.6667 21.6666H18.3334V11.6666H21.6667V21.6666Z" />
              </svg>
            </div>

            <div class="px-4 py-2 -mx-3">
              <div class="mx-3">
                <span class="font-semibold text-blue-900">{{ notification.title }}</span>
                <p class="text-sm text-gray-600">
                  {{ notification.text }}
                </p>
              </div>
            </div>
          </div>
        </Notification>
      </div>
    </div>
  </NotificationGroup>
  <footer class="fixed z-50 bottom-12 w-75 right-1">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div
      v-if="getShowPrivacyConsent"
      class="py-3 px-3 sm:px-6 lg:px-8 pb-3 bg-indigo-900"
    >
      <div class="flex flex-wrap items-center justify-between">
        <div class="flex w-0 flex-1 items-center">
          <p class="ml-3 font-medium md:text-lg text-white text-justify">
            <b>
              <svg
                class="h-8 w-8 text-white inline-block"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 110-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 01-1.44-4.282m3.102.069a18.03 18.03 0 01-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 018.835 2.535M10.34 6.66a23.847 23.847 0 008.835-2.535m0 0A23.74 23.74 0 0018.795 3m.38 1.125a23.91 23.91 0 011.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 001.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 010 3.46"
                />
              </svg>
              {{ isEnglish() ? 'Privacy' : 'Datenschutz' }}</b><br><br>
            <span>{{ isEnglish() ? 'We do NOT use cookies or tracking technologies. With your consent, your personal preferences (language, text size, etc.) will be stored in the browser\'s local cache, which will continue to exist and can be read even after the browser window is closed or the program is exited - provided the cache is not deleted. By clicking on "Accept all" you consent to the storage and reading of information on your end device. You can change your decision at any time under ' : 'Wir nutzen KEINE Tracking-Technologien oder Cookies. Ihre persönlichen Präferenzen (Sprache, Textgröße etc.) werden aber bei Einwilligung im lokalen Cache des Browsers gespeichert, die auch nach dem Schließen des Browser-Fensters oder dem Beenden des Programms weiterhin bestehen und ausgelesen werden können. Mit dem Klick auf „Alles akzeptieren“ willigen Sie in das Speichern und Lesen von Informationen auf Ihrem Endgerät ein. Sie können Ihre Entscheidung unter ' }}
              <b>
                <router-link
                  to="/privacy"
                  class="pb-1"
                  tabindex="0"
                  @click="toggleNav"
                >
                  <span class="text-white hover">
                    {{ isEnglish() ? 'data protection' : 'Datenschutz ändern' }}
                  </span>
                </router-link>
              </b>
            </span>
          </p>
        </div>
      </div>
      <div class="grid grid-flow-col place-items-center ml-5 mr-5 mt-2 pt-3 pb-3">
        <!--button type="button" class="justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-lg font-medium text-indigo-600 shadow-sm hover:bg-indigo-50" @click="dialogState = true; setStorageAccept(false)">
        {{ isEnglish() ? 'Customize' : 'Anpassen' }}
      </!--button-->
        <button
          type="button"
          class="justify-center ml-2 rounded-md border border-transparent bg-gray-500 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-50 hover:text-black"
          @click="setStorageAccept(false)"
        >
          {{ isEnglish() ? 'FORBID ALL' : 'ALLES ABLEHNEN' }}
        </button>
        <button
          type="button"
          class="justify-center ml-5 rounded-md border border-transparent bg-green-400 px-4 py-2 font-medium shadow-sm text-black hover:bg-indigo-50"
          @click="setStorageAccept(true);"
        >
          {{ isEnglish() ? 'ALLOW ALL' : 'ALLES AKZEPTIEREN' }}
        </button>
      </div>
    </div>
  </footer>
  <GDialog
    v-model="dialogState"
    max-width="500"
  >
    <div class="wrapper bg-indigo-900 text-white">
      <div class="content">
        <div class="title">
          {{ isEnglish() ? 'Settings' : 'Einstellungen' }}
        </div>
        <p />
        <div class="flex items-center mb-4">
          <input
            id="disabled-checkbox"
            type="checkbox"
            value=""
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          >
          <label
            for="disabled-checkbox"
            class="ml-2 text-lg font-medium text-gray-400 dark:text-gray-500"
          >Disabled checkbox</label>
        </div>
        <div class="flex items-center">
          <input
            id="disabled-checked-checkbox"
            checked=""
            type="checkbox"
            value=""
            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
          >
          <label
            for="disabled-checked-checkbox"
            class="ml-2 text-lg font-medium text-gray-400 dark:text-gray-500"
          >Disabled checked</label>
        </div>
      </div>

      <div class="actions">
        <button
          class="btn btn--outline-gray"
          @click="dialogState = false"
        >
          {{ isEnglish() ? 'Submit' : 'Bestätigen' }}
        </button>
      </div>
    </div>
  </GDialog>
</template>
<script>
  import { ref } from 'vue'
  import Mixins from '@/Mixins'
  import { notify } from "notiwind"

  export default {
    mixins: [Mixins],
    setup() {
      let showMenu = ref(false);
      const toggleNav = () => (showMenu.value = !showMenu.value);
      let storageAccept = localStorage.getItem("storage-accepted") === 'true';
      const currentLanguage = ref('de');
      const fontSize = ref(((storageAccept && localStorage.getItem("fontSize")) ? localStorage.getItem("fontSize") : '1.1rem'));
      const userTheme = ref('light-theme');
      const dialogState = ref(false);

      return {
        currentLanguage,
        fontSize,
        userTheme,
        dialogState,
        showMenu,
        toggleNav
      }
    },
    data() {
     return {
        logo: require('@/assets/images/logo_brand.png'),
        englishIcon: require('@/assets/images/union-jack.png'),
        englishIconAlt: 'union jack',
        germanIcon: require('@/assets/images/german.png'),
        germanIconAlt: this.isEnglish() ? 'German Flag' : 'Deutsche Flagge'
      }
    },
    computed: {
      backgroundColor() {
        return this.$userTheme.value === 'dark-theme' ? 'black' : 'white'
      },
      fontColor() {
        return this.$userTheme.value === 'dark-theme' ? 'white' : 'black'
      },
      getShowPrivacyConsent() {
        return this.$showPrivacyBanner.value;
      },
      isAuthenticated() {
        return this.$store.getters["security/isAuthenticated"];
      }
    },
    mounted() {
      let storageAccept = localStorage.getItem("storage-accepted") === 'true';
      this.$showPrivacyBanner.value = !storageAccept;
      this.$currentLanguage.value = this.currentLanguage = (storageAccept && localStorage.getItem("language")) ? localStorage.getItem("language") : 'de'
      this.$fontSize.value = this.fontSize = (storageAccept && localStorage.getItem("fontSize")) ? localStorage.getItem("fontSize") : '1.1rem';
      if (storageAccept && localStorage.getItem("user-theme") === 'dark-theme') {
        this.$userTheme.value = 'dark-theme';
        this.setTheme(this.$userTheme.value)          
      }
    },
    methods: {
      toggleTheme() {
        const activeTheme = this.$userTheme.value;
        if (activeTheme === "light-theme") {
          this.setTheme("dark-theme");
        } else {
          this.setTheme("light-theme");
        }
      },
      getTheme() {
        return localStorage.getItem("user-theme");
      },
      setTheme(theme) {
        if (this.getStorageAccept()) {
          localStorage.setItem("user-theme", theme);
        }
        this.$userTheme.value = theme
        document.documentElement.className = theme;
      },
      isDark() {
        let currentDate = new Date();
        let sunrise = currentDate.sunrise(this.lat, this.lng).getTime();
        let sunset = currentDate.sunset(this.lat, this.lng).getTime();
        let now = currentDate.getTime();

        if (sunrise < now && now < sunset) {
          return false;
        } else {
          return true;
        }
      },
      changeLanguage(lang) {
        this.currentLanguage = lang;
        this.$currentLanguage.value = lang
        if (this.getStorageAccept()) {
          localStorage.setItem("language", lang);
        }
      },
      changeFontSize(size) {
        this.fontSize = size;
        this.$fontSize.value = size
        if (this.getStorageAccept()) {
          localStorage.setItem("fontSize", size);
        }
      },
      isEnglish() {
        return this.currentLanguage == 'en';
      },
      setStorageAccept(accept) {
        localStorage.setItem("storage-accepted", accept);
        this.$showPrivacyBanner.value = false;
        notify({
            group: "info",
            title: "Local Storage",
            text: this.isEnglish() ? "Local storage " + (accept ? "accepted." : "declined.") : "Lokales Speichern im Local Storage " + (accept ? "akzeptiert." : "abgelehnt.")
          }, 4000)
          return;
      },
      getStorageAccept() {        
        return localStorage.getItem("storage-accepted") === 'true';
      },
      reloadPage() {
        if (window.location.href == window.location.origin + '/#/') {
          window.location.href = window.location.origin;
          window.location.reload();
        }
      }
    }
  }
  
</script>
<style>
  * {
    box-sizing: border-box;
  }
  body {
    margin: 10px;
    padding: 0;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .autocomplete {
    display: inline-block;
    position: relative;
  }
  input {
    background-color: #f1f1f1;
    border-radius: 4px;
    font-size: 16px;
    padding: 10px;
  }
  input[type="text"] {
    background-color: #e1f6fc;
    width: 100%;
  }
  input:invalid {
    border: 2px solid red;
  }
  input[type="submit"] {
    background-color: DodgerBlue;
    color: #fff;
    cursor: pointer;
  }
  .autocomplete-address {
    background-color: #e9e9e9;
    border-bottom: 1px solid #d4d4d4;
    border-radius: .28571429rem;
    border-top: none;
    cursor: pointer;
    left: 0;
    padding: 10px;
    position: relative;
    right: 0;
    top: 0;
    z-index: 99;
  }
  .autocomplete-address:hover {
    background: rgb(26, 149, 55);
  }
  .autocomplete-active {
    background-color: #db2828 !important;
    color: #ffffff;
  }
  .right-align {
    text-align: right;
  }
  .hidden {
    display: none;
  }
  #flag path {
    stroke-width: 0;
    fill: #080;
  }
  .item {
    min-height: 3.5em !important;
  }
  .item.green {
    border-top: 8px solid green !important;
  }
  .item.red {
    border-top: 8px solid red !important;
  }
  .item.blue {
    border-top: 8px solid blue !important;
  }
  .item.black {
    border-top: 8px solid black !important;
  }
  .item.rainbow {
  background-image: repeating-linear-gradient(to bottom,
    #c4e17f 0px, #c4e17f 1px,
    #f7fdca 8px, #f7fdca 8px,
    #fad071 16px, #fad071 16px,
    #f0766b 24px, #f0766b 24px,
    #db9dbe 32px, #db9dbe 32px,
    #c49cdf 40px, #c49cdf 40px,
    #6599e2 48px, #6599e2 48px,
    #61c2e4 56px, #61c2e4 56px) !important;
    background-size: 8px 100% !important;
    background-repeat:no-repeat !important;
  }

  #navbar {
    /* background: v-bind(backgroundColor); */
    background: #44475C;
  }

  #navbar_settings {
    font-size: v-bind(fontSize);
  }

  form,
  .result-list {
    background: transparent !important;
  }

  button.ui.button,
  .result-list {
    font-size: v-bind(fontSize);
  }

  #app {
    width: 100%;
  }

  #app form label,
  #app form .label {
    font-size: v-bind(fontSize);
  }

  #app label,
  #app .label {
    color: v-bind(fontColor);
    /* color: white; */
  }

  /* Define styles for the default root window element */
:root {
  --background-color-primary: #ebebeb;
  --background-color-secondary: #fafafa;
  --accent-color: #cacaca;
  --text-primary-color: #222;
  --element-size: 4rem;
  --link-color: #5AD67D;
  --link-color-text: #0A562D;
  --submit-color: #222;
}

/* Define styles for the root window with dark - mode preference */
:root.dark-theme {
  --background-color-primary: #3e3e3e;
  --background-color-secondary: #2d2d30;
  --accent-color: #cacaca;
  --text-primary-color: blanchedalmond;
  --link-color: #5AD67D;
  --link-color-text: #5AD67D;
}

body {
  background-color: var(--background-color-primary);
}

.description {
  background-color: var(--background-color-secondary);
  color: var(--text-primary-color);
}

h1, p {
  color: var(--text-primary-color);
}

h3 {
  text-decoration: underline;
}

form {
  border: 2px solid var(--accent-color) !important;
  margin-bottom: 0.7em;
  margin-top: 0 !important;
}

.container-center {
  align-items: center;
  background-color: var(--background-color-primary);
  display: flex;
  font-size: v-bind(fontSize);
  justify-content: center;
}

.ui.segment, .ui.segments .segment .result-list {
  font-size: v-bind(fontSize);
}

.switch-label {
  align-items: center;
  border: calc(var(--element-size) * 0.025) solid var(--accent-color);
  border-radius: var(--element-size);
  cursor: pointer;
  display: flex;
  font-size: calc(var(--element-size) * 0.3);
  justify-content: space-between;
  height: calc(var(--element-size) * 0.5);
  max-width: fit-content;
  padding: calc(var(--element-size) * 0.1);
  position: relative;
  transition: background 0.5s ease;
  width: var(--element-size) * 1.1;
  z-index: 1;
}

.switch-toggle {
  background: #ebebeb;
  border-radius: 50%;
  color: black;
  height: calc(var(--element-size) * 0.5);
  left: -1px;
  position: absolute;
  transform: translateX(0);
  transition: transform 0.3s ease, background-color 0.5s ease;
  width: calc(var(--element-size) * 0.5);
}

.switch-toggle-checked {
  transform: translateX(calc(var(--element-size) * 0.51));
}

.select-none {
  color: white !important;
}

#app .ui.button {
  padding: .5em .75em;
}

.active,
.focus:focus,
.hover:hover {
  /* border-bottom: 2px solid v-bind(fontColor); */
  border-bottom: 2px solid white;
}

.required::after {
  content: '*'
}

.blog-card {
    background: v-bind(backgroundColor);
    border-radius: 5px;
    box-shadow: 0 3px 7px -1px rgba(0, 0, 0, .1);
    display: flex;
    flex-direction: column;
    font-family: sans-serif;
    font-size: v-bind(fontSize);
    line-height: 1.4;
    margin: 1rem auto;
    margin-bottom: 1.6%;
    overflow: hidden;
    z-index: 0;
  }
  .blog-card a:hover {
    color: var(--link-color)
  }
  .blog-card:hover .photo {
    transform: scale(1.3) rotate(3deg);
  }
  .blog-card .meta {
    height: 200px;
    position: relative;
    z-index: 0;
  }
  .blog-card .photo {
    background-size: cover;
    background-position: center;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: transform 0.2s;
  }
  .blog-card .details, .blog-card .details ul {
    list-style: none;
    margin: auto;
    padding: 0;
  }
  .blog-card .details {
    background: rgba(0, 0, 0, .6);
    bottom: 0;
    color: #fff;
    font-size: 0.9rem;
    left: -100%;
    margin: auto;
    padding: 10px;
    position: absolute;
    top: 0;
    transition: left 0.2s;
    width: 100%;
  }
  .blog-card .details ul li {
    display: inline-block;
  }
  .blog-card .details .tags li {
    margin-right: 2px;
  }
  .blog-card .details .tags li:first-child {
    margin-left: -4px;
  }
  .blog-card .description {
    padding: 1rem;
    position: relative;
    z-index: 1;
  }
  .blog-card .description h1,
  .blog-card .description h2,
  .description h3 {
    font-family: Poppins, sans-serif;
  }
  .blog-card .description h1 {
    font-size: 1.7rem;
    line-height: 1;
    margin: 0;
  }
  .blog-card .description h2 {
    font-size: 1.5rem;
    line-height: 1;
    margin: 2rem 0 0 0;
    text-transform: uppercase;
  }
  .blog-card .description h3 {
    font-size: 1.2rem;
    line-height: 1;
    margin: 1.5rem 0;
    text-transform: uppercase;
  }
  .blog-card .description .read-more {
    text-align: right;
  }
  #app a {
    color: var(--link-color);
    display: inline-block;
    position: relative;
  }
  #app .blog-card .description a {
    color: var(--link-color-text);
    display: inline-block;
    position: relative;
  }
  #app #map a {
    color: var(--submit-color)
  }
  #app #map button {
    background: var(--submit-color)
  }
  .blog-card .description .read-more a:hover:after {
    margin-left: 5px;
    opacity: 1;
  }
  .blog-card p {
    position: relative;
    margin: 1rem 0 0;
  }
  .blog-card p:first-of-type {
    margin-top: 1.25rem;
  }
  .blog-card p:first-of-type:before {
    background: var(--link-color);
    border-radius: 3px;
    height: 5px;
    position: absolute;
    top: -0.75rem;
    width: 35px;
  }
  .blog-card:hover .details {
    left: 0%;
  }
  @media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 700px;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description.full-width {
    flex-basis: 100%;
  }
  .blog-card .description:before {
    background: #fff;
    bottom: 0;
    left: -10px;
    position: absolute;
    top: 0;
    transform: skewX(-3deg);
    width: 30px;
    z-index: -1;
  }
}

.wrapper {
  color: #000;
}

.content {
  padding: 20px;
}

.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 20px;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}


table {
  border-collapse: collapse;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  table-layout: fixed;
  word-break: break-word;
}

table th[scope=col],
#resultTableMobile h2 {
  background: #44475C;
  color: #FFF;
  cursor: pointer;
  min-width: 110px;
  padding: 8px;
  text-align: left;
  text-transform: uppercase;
}

#resultTableMobile h2,
#resultTableMobile table th[scope=col],
#resultTableMobile dt {
  background: #34374C;
}

#resultTableMobile dt:focus,
#resultTableMobile dt:hover,
#resultTableMobile h2:focus,
#resultTableMobile h2:hover {
  background: purple;
  cursor: pointer;
}

dl {
  background: #dbe0da;
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  overflow: hidden;
  width: 100%;
}

dt {
  background: #717699;
  color: white;
  cursor: pointer;
  float: left;
  min-width: 10ch;
  padding-left: 1%;
  width: 20%;
}
dd {
    float: left;
    width: 70%;
    padding-left: 2%;
    white-space: nowrap;
    overflow: hidden;              /* "overflow" value must be different from  visible"*/ 
    -o-text-overflow: ellipsis;    /* Opera < 11*/
    text-overflow:    ellipsis;    /* IE, Safari (WebKit), Opera >= 11, FF > 6 */
}
table td,
table th[scope=row] {
  border-right: 2px solid #7D82A8;
  font-weight: normal;
  padding: 8px;
  text-align: left;
}
table td:last-child {
  border-right: none;
}
table tbody tr {
  color: v-bind(fontColor);
  border-bottom: 1px solid purple;
}
.text-total {
  color: v-bind(fontColor);
}

table tbody tr:nth-child(2n) {
  background-color: v-bind(backgroundColor);
  color: v-bind(fontColor);
}
.activeRow {
  background-color: purple !important;
  color: white !important;
}
#resultTable {
  background-color: var(--background-color-primary);
}

.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible {
  display: none !important;
}

@media (max-width: 800px) {
  body ::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 0;
    height: 10px;
  }
}
</style>
