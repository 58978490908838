// src/services/location.js
import axios from 'axios';

const ENDPOINT = 'https://nominatim.openstreetmap.org/search';
const ENDPOINT_REVERS = 'https://nominatim.openstreetmap.org/reverse';
const ENDPOINT_AUTOCOMPLETE = 'https://www.demo-alarm.de/api/autosuggest.php';
const FORMAT = 'jsonv2';

export function currentCoordinates() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      ({ coords }) => resolve(coords),
      // Reject if the user doesn't
      // allow accessing their location.
      error => reject(error),
    );
  });
}

export async function addressByCoordinates({ latitude, longitude }) {
  const { data } = await axios.get(ENDPOINT_REVERS, {
    params: {
      format: FORMAT,
      lat: latitude,
      lon: longitude,
    },
  });

  data.address.city = data.address.city ?? data.address.county ?? ''

  return data.address
}

export async function currentAddress() {
  const coordinates = await currentCoordinates();

  return addressByCoordinates(coordinates);
}

export async function coordinatesByAddress({ city, postalcode, housenumber, street }) {

  let params = {
    format: FORMAT,
    country: "Germany"
  };

  if (city !== null && city !== undefined) {
    params["city"] = city;
  }

  if (street !== null && street !== undefined) {
    params["street"] = street;
    if (housenumber !== null && housenumber !== undefined) {
      params["street"] = housenumber + ' ' + params["street"];
    }
  }

  if (postalcode !== null && postalcode !== undefined) {
    params["postalcode"] = postalcode;
  }

  const { data } = await axios.get(ENDPOINT, {
    params: params,
  });

  return data;
}

export async function autocomplete(search, type) {
  return await axios.get(ENDPOINT_AUTOCOMPLETE + '?search=' + search + "&type=" + type);
}
