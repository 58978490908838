import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import notify from 'notiwind'
import './assets/tailwind.css'
import { ref } from 'vue'
import 'gitart-vue-dialog/dist/style.css'
import { GDialog } from 'gitart-vue-dialog'
import VueMobileDetection from 'vue-mobile-detection'
import store from "./store";

const app = createApp(App)
    .use(router)
    .use(notify)
    .use(VueMobileDetection)
    .use(store)
    .component('GDialog', GDialog)

    // TODO: read local storage or use time
    // cache nach 3 Tagen invalidieren
    // TODO: Anzahl Teilnehmer
    // TODO: Englisch Api
    // TODO: Uhrzeit
    // TODO: Links Tabelle für Ergebnisse (ein / ausklappbar)
    // TODO: Per Zoom alle auf Kartenausschnitt anzeigen
    // TODO: Elemente nicht fokussierbar setzen
    // Link um Versammlungen zu melden:
    // München https://stadt.muenchen.de/service/info/hauptabteilung-i-sicherheit-und-ordnung-praevention/1063788/
    // https://koeln.polizei.nrw/artikel/anzeige-von-versammlungen
app.config.globalProperties.$currentLanguage = ref("de");
app.config.globalProperties.$fontSize = ref('1.1rem');
app.config.globalProperties.$userTheme = ref('light-theme');
app.config.globalProperties.$showPrivacyBanner = ref(true);
app.config.globalProperties.$selectDemo = ref({});
app.mount('#app');