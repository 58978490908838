import axios from "axios";

export default {
  login(login, password) {
    // console.log(process.env.VUE_APP_URL);
    axios.defaults.withCredentials = true;
    // let retval = process.env.VUE_APP_URL; - not working
    let url = `https://demo-alarm.de/api2/public/index.php`;
    if (location.origin.includes('berlin')) {
      url = `https://berlin.demo-alarm.de/api2/public/index.php`;
    }
    if (location.origin.includes('localhost')) {
      url = `http://localhost:8085/api2/public/index.php`;
    }
    let retval = axios.post(url + "/login", {
      username: login,
      password: password
    });
    axios.defaults.withCredentials = false;
    return retval;
  }
}