import { createWebHashHistory, createRouter } from "vue-router";
import Home from "../views/Home.vue";
import Imprint from "../views/Imprint.vue";
import Privacy from "../views/Privacy.vue";
import Report from "../views/Report.vue";
import Login from "../views/Login.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Demonstrationen finden",
    },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: {
      title: "Neue Demonstrationen melden",
      meta: {
        requiresAuth: true
      }
    },
  },
  {
    path: "/imprint",
    name: "Imprint",
    component: Imprint,
    meta: {
      title: "Impressum",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "Einloggen",
    },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: Privacy,
    meta: {
      title: "Datenschutz",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters["security/isAuthenticated"]) {
        next();
      } else {
        // console.log("next login");
        next({
          path: "/login",
          query: { redirect: to.fullPath }
        });
      }
    } else {
      next(); // make sure to always call next()!
    }
});

export default router;
