/* eslint-disable */
<template>
  <div
    v-if="isLoadingCurrentLocation || isLoadingDemos"
    class="loading"
  >
    <div class="loader" />
  </div>
  <div
    class="fixed right-2 md:right-5 bottom-3 z-50"
    :class="getFontSize == '1.1rem' ? 'pt-2' : 'pt-1'"
  >
    <button
      v-if="isMapLoaded"
      type="button"
      class="ui button"
      :aria-label="isEnglish ? 'Open or Hide Search Form ' : 'Suchformular öffnen oder verbergen'"
      :class="!isSearchFormCollapsed ? 'violet' : 'black line-through'"
      style="margin-right: 5px"
      :style="{ fontSize: getFontSize}"
      @click="toggleSearchForm()"
    >
      <i class="search icon" />
      <span class="hidden xl:inline-block">
        {{ isEnglish() ? 'Edit Search' : 'Suche ändern' }}
      </span>
    </button>
    <button
      v-if="locationFound"
      type="button"
      class="ui button"
      :aria-label="isEnglish ? 'Show or hide List View' : 'Liste anzeigen oder verbergen'"
      :class="isListViewVisible ? 'violet' : 'black line-through'"
      style="margin-right: 5px"
      :style="{ fontSize: getFontSize}"
      @click="toggleList()"
    >
      <i class="list alternate icon" />
      <span
        class="hidden xl:inline-block"
        :class="isListViewVisible ? '' : 'line-through'"
      >
        {{ isEnglish() ? 'List View' : 'Liste' }}
      </span>
    </button>
    <button
      v-if="locationFound"
      type="button"
      class="ui button"
      :aria-label="isEnglish ? 'Show or hide map' : 'Kartensicht anzeigen oder verstecken'"
      :class="isMapVisible ? 'violet' : 'black line-through'"
      :style="{ fontSize: getFontSize}"
      @click="toggleMap"
    >
      <i class="map marker alternate icon" />
      <span
        class="hidden xl:inline-block"
        :class="isMapVisible ? '' : 'line-through'"
      >
        {{ isEnglish() ? 'Map' : 'Karte' }}
      </span>
    </button>
    <button
      v-if="searchDemoResult && searchDemoResult.length"
      type="button"
      class="ui button violet mt-2 ml-2"
      style="margin-left: 5px; black"
      aria-label="{{ isEnglish() ? 'Share search' : 'Suche teilen' }}"
      @click="shareSearch()"
    >
      <i class="share alternate icon" />
      <span class="hidden xl:inline-block">
        {{ isEnglish() ? 'Share search' : 'Suche teilen' }}
      </span>
    </button>
  </div>
  <div
    id="home"
    role="main"
    class="grid grid-cols-1 relative"
    :class="!$isMobile() && isListViewVisible && isMapVisible ? 'lg:grid-cols-2' : ''"
    @click="closeAllLists"
  >
    <h1
      class="fixed left-2 md:left-4 bottom-1 z-50 text-transparent"
      role="heading"
    >
      Demos
    </h1>
    <div
      v-if="!isSearchFormCollapsed || isListViewVisible"
      id="sidebar"
      class="z-40"
    >
      <div class="max-w-3xl ml-1 mb-1">
        <form
          v-if="!isSearchFormCollapsed"
          class="ui segment large form"
          autocomplete="new-password"
          role="search"
        >
          <div class="field">
            <div class="ui right icon input large">
              <input
                v-model="coordinates"
                type="text"
                title="coordinate"
                tabindex="-1"
                placeholder=""
                hidden
                aria-hidden="true"
              >
              <button
                v-if="isMapLoaded"
                type="button"
                class="ui button black"
                @click="collapseSearchForm"
              >
                <span>
                  <i class="close icon" /> {{ isEnglish() ? 'Hide Form' : 'Formular ausblenden' }}
                </span>
              </button>
              <button
                v-if="geolocationSupported"
                type="button"
                class="ui button violet"
                @click="fetchCoordsAndAddress"
              >
                <i
                  v-if="geolocationSupported"
                  :disabled="isLoadingCurrentLocation || isLoadingDemos"
                  class="bullseye icon white"
                  @click="fetchCoordsAndAddress"
                />
                <span>
                  {{ isLoadingCurrentLocation ? isEnglish() ? 'Fetching Location ...' : 'Lade Standort ...' : isEnglish() ? 'Get Location' : 'Standort abrufen' }}
                </span>
              </button>
            </div>
          </div>
          <div class="field">
            <div class="three fields">
              <div class="field eight wide column">
                <label
                  class="required"
                  for="startDate"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ fromLabel }}</label>
                <input
                  id="startDate"
                  v-model="startDate"
                  type="date"
                  name="date"
                  :placeholder="datePlaceholder"
                  :title="fromAria"
                  @keyup="keyUpStartDate"
                >
              </div>
              <div class="field eight wide column">
                <label
                  class="required"
                  for="endDate"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ toLabel }}</label>
                <!--select
                  id="type"
                  v-model="type"
                >
                  <option value="restaurant">
                    Restaurant
                  </option>
                </!--select-->
                <input
                  id="endDate"
                  v-model="endDate"
                  type="date"
                  name="date"
                  :title="toAria"
                  :placeholder="datePlaceholder"
                >
              </div>
            </div>
          </div>
          <div>
            <label>
              {{ isEnglish() ? 'Enter either city or zip code.' : 'Entweder Ort oder Postleitzahl angeben.' }}           
            </label>
          </div>
          <div class="field">
            <div class="two fields">
              <div class="field eleven wide column">
                <label
                  for="city"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ placeLabel }}</label>
                <input
                  id="city"
                  v-model="address.city"
                  name="city"
                  autocomplete="new-password"
                  :placeholder="placePlaceholder"
                  @keydown="keyDownAutocomplete"
                  @keyup="keyUpAutocomplete"
                >
                <div
                  id="autocomplete-city"
                >
                  <div
                    v-for="(suggest, index) in suggestsCity"
                    :key="index"
                    class="autocomplete-address"
                    @click="selectCity"
                  >
                    <span
                      class="index"
                      hidden
                    >{{ index }}</span>
                    {{ suggest.label }}
                  </div>
                </div>
              </div>
              <div class="field five wide column">
                <label
                  for="postcode"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ postalLabel }}</label>
                <input
                  id="postcode"
                  v-model="address.postcode"
                  name="postcode"
                  pattern="[0-9]+"
                  :placeholder="postalPlaceholder"
                  autocomplete="new-password"
                  @keydown="keyDownAutocomplete"
                  @keyup="keyUpAutocomplete"
                >
                <div
                  id="autocomplete-postcode"
                >
                  <div
                    v-for="(suggest, index) in suggestsPostcode"
                    :key="index"
                    class="autocomplete-address"
                    @click="selectPostcode"
                  >
                    <span
                      class="index"
                      hidden
                    >{{ index }}</span>
                    {{ suggest.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--div class="field">
            <div class="two fields">
              <div class="field eleven wide column">
                <label for="street" :style="{ color: isDarkMode() ? 'white' : 'black'}">Straße</label>
                <input
                  id="street"
                  v-model="address.road"
                  name="street"
                  placeholder="Straße"
                  autocomplete="new-password"
                  @keyup.enter="findDemosCloseBy"
                >
              </div>
              <div class="field five wide column">
                <label for="street_number" :style="{ color: isDarkMode() ? 'white' : 'black'}">HausNr</label>
                <input
                  id="street_number"
                  v-model="address.house_number"
                  name="street_number"
                  placeholder="Hausnummer"
                  autocomplete="new-password"
                  @keyup.enter="findDemosCloseBy"
                >
              </div>
            </div>
          </div -->

          <div class="field">
            <div class="three fields">
              <div class="field eleven wide column">
                <label
                  for="topic"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ topicLabel }}</label>
                <input
                  id="topic"
                  v-model="topic"
                  name="topic"
                  autocomplete="new-password"
                  :placeholder="topicPlaceholder"
                  @keyup.enter="findDemosCloseBy"
                >
              </div>
              <div class="field five wide column">
                <label
                  for="radius"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ circleLabel }}</label>
                <select
                  id="radius"
                  v-model="radius"
                >
                  <option value="3">
                    3 km
                  </option>
                  <option value="10">
                    10 km
                  </option>
                  <option value="30">
                    30 km
                  </option>
                  <option value="100">
                    100 km
                  </option>
                </select>
                <div
                  class="right-align label"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >
                  in km
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="ui right icon input large">
              <button
                ref="submit"
                :disabled="isLoadingCurrentLocation || isLoadingDemos"
                class="ui button violet"
                type="button"
                autofocus
                @click="findDemosCloseBy"
              >
                <i class="search icon" />
                <span>
                  {{ isEnglish() ? 'Search' : 'Suchen' }}
                </span>
              </button>
              <button
                type="button"
                class="ui button black"
                @click="clearAddress"
              >
                <span>
                  <i class="trash alternate icon" /> {{ isEnglish() ? 'Clear Inputs' : 'Eingaben leeren' }}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        v-if="isMapLoaded && isListViewVisible"
        class="result-list pl-0 md:pl-1 mb-9 md:mb-0"
        style="height: 83vh;"
      >
        <vue-resizable
          :active="handlers"
          :left="0"
          @mount="resizableMountTableHandler"
          @resize:move="resizableTableHandler"
          @resize:end="resizableEndTableHandler"
        >
          <div class="resizable-content overflow-hidden md:overflow-auto">
            <div id="resultTable" class="table-responsive">
              <table
                class="table table-bordered nowrap"
                width="100%;"
              >
                <colgroup>
                  <col style="width:61%">
                  <col style="width:22%">
                  <col style="width:17%">
                </colgroup>  
                <thead class="sticky top-0">
                  <tr
                    :style="{ color: isDarkMode() ? 'white' : 'black'}"
                  >
                    <th
                      scope="col"
                      tabindex="0"
                      role="columnheader"
                      :aria-sort="sortColumn === 'topic' ? (ascending === true ? 'ascending' : 'descending') : 'none'"
                      @click="sortTable('topic')"
                      @keyup.enter="sortTable('topic')"
                    >
                      {{ isEnglish() ? 'Topic' : 'Thema' }}
                      <div
                        v-if="'topic' == sortColumn"
                        class="arrow"
                        :class="ascending ? 'arrow_up' : 'arrow_down'"
                      >
                      <!--div v-html="getArrow()"></!div-->
                      </div>
                    </th>
                    <th
                      scope="col"
                      tabindex="0"
                      role="columnheader"
                      :aria-sort="sortColumn === 'street' ? (ascending === true ? 'ascending' : 'descending') : 'none'"
                      @click="sortTable('street')"
                      @keyup.enter="sortTable('street')"
                    >
                      {{ isEnglish() ? 'Place' : 'Ort' }}
                      <div
                        v-if="'street' == sortColumn"
                        class="arrow"
                        :class="ascending ? 'arrow_up' : 'arrow_down'"
                      />
                    </th>
                    <th
                      tabindex="0"
                      scope="col"
                      role="columnheader"
                      :aria-sort="sortColumn === 'start_date' ? (ascending === true ? 'ascending' : 'descending') : 'none'"
                      @click="sortTable('start_date')"
                      @keyup.enter="sortTable('start_date')"
                    >
                      {{ isEnglish() ? 'Time' : 'Zeit' }}
                      <div
                        v-if="'start_date' == sortColumn"
                        class="arrow"
                        :class="ascending ? 'arrow_up' : 'arrow_down'"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody
                  :class="isDarkMode() ? 'text-white' : 'text-black'"
                  :style="{ fontSize: getFontSize}"
                >
                  <tr
                    v-for="demo in demos"
                    :id="demo.id"
                    :key="demo.id"
                    :class="{ 'activeRow': demo === selectDemo }"
                    tabindex="0"
                    @click="clickDemoOnList(demo)"
                    @keyup.tab="clickDemoOnList(demo)"
                  >
                    <th scope="row">
                      {{ demo === selectDemo ? demo['topic_desc'] + (demo['route_desc'] ? ', ' + demo['route_desc'] : '') : demo['topic'] }}
                      <button
                        v-if="isAuthenticatedCookie"
                        type="button"
                        class="ui button white mt-3 ml-2"
                        style="margin-left: 5px; black"
                        :title="deleteLabel"
                        :aria-label="deleteLabel"
                        @click="deleteDemoById(demo.id)"
                      >
                        <i class="delete alternate icon text-red" />
                        <i
                          v-if="!demo.isPublished"
                          class="bell outline icon text-red"
                        />
                      </button>
                      <a
                        v-if="demo === selectDemo"
                        class="font-bold"
                        style="margin-left: 10px; font-size: 17px;"
                        tabindex="0"
                        :title="shareLabel"
                        @click="shareDemo(demo.id)"
                        @keyup.enter="shareDemo(demo.id)"
                      >
                        <i class="share alternate icon text-lg" />
                      </a>
                    </th>
                    <td>
                      {{ demo['street'] }}<br>{{ demo === selectDemo ? demo['zip'] + ' ' + demo['city'] : '' }}
                      <span 
                        v-if="demo === selectDemo"
                      >
                        <a
                          target="_blank"
                          style="margin-left: 5px; font-size: 17px; font-weight: bolder"
                          :title="isEnglish() ? 'Open in Google Maps' : 'In Google Maps öffnen'"
                          :href="'https://www.google.com/maps/place/' + demo['lat'] + ',' + demo['lon'] + '/@werwerwerwer' + demo['lat'] + ',' + demo['lon'] + ',50z+(label)'"
                        ><i class="map marker alternate icon text-lg" />
                        </a>
                      </span>
                    </td>
                    <td>{{ demo['start_date'] }}<br>{{ demo === selectDemo ? demo['start_time'] === '00:00' ? '' : (isEnglish() ? demo['start_time_english'] : demo['start_time'].replace(":00", "")) + ' - ' + (isEnglish() ? demo['end_time_english'] : demo['end_time'].replace(":00", "") + ' Uhr') : (demo['start_time'] === '00:00' ? '' : isEnglish() ? demo['start_time_english'] : demo['start_time'] + ' Uhr') }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              id="resultTableMobile"
            >
            <div
              v-for="demo in demos"
              :key="demo.id"
            >
              <h2 tabindex="0"
                @click="clickDemoOnList(demo)"
                @keyup.tab="clickDemoOnList(demo)"
              >
                <a
                  v-if="demo === selectDemo"
                  class="font-bold"
                  style="margin-left: 10px; font-size: 17px;"
                  tabindex="0"
                  :title="shareLabel"
                  @click="shareDemo(demo.id)"
                  @keyup.enter="shareDemo(demo.id)"
                >
                  <i class="share alternate icon text-xl" />
                </a>
                {{ demo === selectDemo ? demo['topic_desc'] + (demo['route_desc'] ? ', ' + demo['route_desc'] : '') : demo['topic'] }}
              </h2>
                <dl>
                  <dt
                    tabindex="0"
                    :aria-sort="sortColumn === 'street' ? (ascending === true ? 'ascending' : 'descending') : 'none'"
                    @click="sortTable('street')"
                    @keyup.enter="sortTable('street')"
                  >
                    <div
                      v-if="'street' == sortColumn"
                      class="arrow"
                      style="color: black;"
                      :class="ascending ? 'arrow_up' : 'arrow_down'"
                    >
                    <!--div v-html="getArrow()"></!div-->
                    </div>
                    <div
                    >
                    {{ isEnglish() ? 'Place' : 'Ort' }}:
                    <!--div v-html="getArrow()"></!div-->
                    </div>
                  </dt>
                  <dd>
                    {{ demo['street'] }}, {{ demo['zip'] + ' ' + demo['city'] }}
                      <span 
                        v-if="demo === selectDemo"
                      >
                        <a
                          target="_blank"
                          style="margin-left: 5px; font-size: 17px; font-weight: bolder"
                          :title="isEnglish() ? 'Open in Google Maps' : 'In Google Maps öffnen'"
                          :href="'https://www.google.com/maps/place/' + demo['lat'] + ',' + demo['lon'] + '/@werwerwerwer' + demo['lat'] + ',' + demo['lon'] + ',50z+(label)'"
                        ><i class="map marker alternate icon text-lg" />
                        </a>
                      </span>
                  </dd>
                  <dt
                      tabindex="0"
                      :aria-sort="sortColumn === 'start_date' ? (ascending === true ? 'ascending' : 'descending') : 'none'"
                      @click="sortTable('start_date')"
                      @keyup.enter="sortTable('start_date')"
                    >
                      {{ isEnglish() ? 'Time' : 'Zeit' }}
                      <div
                        v-if="'start_date' == sortColumn"
                        class="arrow"
                        :class="ascending ? 'arrow_up' : 'arrow_down'"
                      />
                  </dt>
                  <dd>{{ demo['start_date'] }}, {{ demo === selectDemo ? demo['start_time'] === '00:00' ? '' : (isEnglish() ? demo['start_time_english'] : demo['start_time'].replace(":00", "")) + ' - ' + (isEnglish() ? demo['end_time_english'] : demo['end_time'].replace(":00", "") + ' Uhr') : (demo['start_time'] === '00:00' ? '' : isEnglish() ? demo['start_time_english'] : demo['start_time'] + ' Uhr') }}</dd>
                </dl>
              </div>
            </div>
          </div>
        </vue-resizable>
        <div
          class="font-bold ml-3 pt-3"
          :class="isDarkMode() ? 'text-indigo-50' : 'text-black'"
          :style="{ fontSize: getFontSize}"
        >
          {{ isEnglish() ? 'Number of Results: ' : 'Anzahl Ergebnisse: ' }} {{ demos.length }}
        </div>
      </div>
    </div>
    <div
      v-if="isMapVisible"
      id="map_container"
      class="ui segment bg-gray-600"
      :class="isListViewVisible ? 'col-span-1' : 'col-span-2'"
    >
      <MapContainer
        :demos="searchDemoResult"
        :reallatitude="lat"
        :reallongitude="lng"
        :repaint="repaint"
        @selectDemo="handleSelectDemo"
      />
    </div>
  </div>
  <div
    v-if="isMapVisible && (!isListViewVisible || $isMobile())"
  >
    <Inspect
      id="inspect"
      :selected-demo="selectDemo"
    />
  </div>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import axios from "axios"
  import * as locationService from "../services/Location"
  import { notify } from "notiwind" // TODO: Auf / Zuklappen bei Select; Routenplaner
  import { defineAsyncComponent } from 'vue'
  import VueResizable from 'vue-resizable'

  const Inspect = defineAsyncComponent(() => import("../components/Inspect.vue"));
  const MapContainer = defineAsyncComponent(() => import("../components/MapContainer.vue"));

  export default {
    components: {
      Inspect,
      MapContainer,
      VueResizable
    },
    data() {
      let d = new Date();
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      let dateString = `${ye}-${mo}-${da}`;
      return {
        type: "restaurant",
        radius: "30",
        startDate: dateString,
        endDate: dateString,
        topic: '',
        lat: 52, // default Berlin
        lng: 13, // default Berlin
        lastSearch: null,
        demos: [],
        address: {city: 'Berlin'},
        suggestsCity: [],
        suggestsPostcode: [],
        currentFocus: -1,
        error: null,
        geolocationSupported: 'geolocation' in navigator,
        locationFound: false,
        isLoadingCurrentLocation: false,
        isLoadingDemos: false,
        isSearchFormCollapsed: false,
        isListViewVisible: false,
        isMapVisible: false,
        isMapLoaded: false,
        searchDemoResult: [],
        selectDemo: null,
        ascending: true,
        sortColumn: 'start_date',
        handlers: ["r"],
        repaint: 0
      }
    },
    computed: {
      coordinates() {
        return `${this.lat}, ${this.lng}`
      },
      placeLabel() {
        return this.isEnglish() ? 'Place' : 'Ort'
      },
      placePlaceholder() {
        return this.isEnglish() ? 'Locality' : 'Ortschaft'
      },
      topicPlaceholder() {
        return this.isEnglish() ? 'enter keyword ' : 'Stichwort eingeben'
      },
      datePlaceholder() {
        return this.isEnglish() ? new Date().toLocaleDateString('en-CA') : new Date().toLocaleDateString()
      },   
      postalLabel() {
        return this.isEnglish() ? 'Or Postal' : 'Postleitzahl'
      },
      topicLabel() {
        return this.isEnglish() ? 'Topic' : 'Thema'
      },
      postalPlaceholder() {
        return this.isEnglish() ? 'Zip' : 'PLZ'
      }, 
      fromLabel() {
        return this.isEnglish() ? 'From' : 'Vom'
      },
      fromAria() {
        return this.isEnglish() ? 'To narrow down the time period for the search, please select the date for the latest event.' : 'Zum Eingrenzen des Zeitraums für die Suche, wählen Sie bitte das Datum für die späteste Veranstaltung.'
      },
      deleteLabel() {
        return this.isEnglish() ? 'Delete event' : 'Veranstaltung löschen' 
      },
      shareLabel() {
        return this.isEnglish() ? 'Share event' : 'Veranstaltung teilen'
      },
      toLabel() {
        return this.isEnglish() ? 'To' : 'Bis'
      },
      toAria() {
        return this.isEnglish() ? 'To narrow down the time period for the search, please select the date for the earliest event.' : 'Zum Eingrenzen des Zeitraums für die Suche, wählen Sie bitte das Datum für die früheste Veranstaltung'
      },
      circleLabel() {
        return this.isEnglish() ? 'Radius' : 'Umkreis'
      },
      getFontSize() {
        return this.$fontSize.value;
      },
      isAuthenticatedCookie() {
        return document.cookie !== "";
      },
    },
    watch: {
      '$currentLanguage.value': {
        handler: function () {
            this.updateCurrentLanguage()
        }, deep: true
      },
      '$fontSize.value': {
        handler: function () {
            this.updateFontSize()
        }, deep: true
      }
    },
    beforeCreate() {
      // console.log("before created")
    },
    created() {
      // console.log("created")      
      if(this.$route.query.id){
        this.findDemoById(this.$route.query.id)
        return;
      }

      if(this.$route.query.lookfor){
        this.lat = this.$route.query.lat ?? '';
        this.lng = this.$route.query.lng ?? '';
        this.topic = this.$route.query.topic ?? '';
        this.address.postcode = this.$route.query.postcode ?? '';
        this.address.city = this.$route.query.city ?? '';
        this.radius = this.$route.query.radius ?? '';
        this.startDate = this.$route.query.startDate ?? '';
        this.endDate = this.$route.query.endDate ?? '';
        this.findDemosCloseBy(null, true);
        return;
      }

      if (location.origin.includes('berlin')) {
        this.findDemosCloseBy(null, true);
      }
      if (location.origin.includes('dresden')) {
        this.address = { city: 'Dresden' };
        this.lat = 51.05;
        this.lng = 13.74;
        this.findDemosCloseBy(null, true);
      }
    },
    beforeMount() {
      // console.log("beforeMount")
    },
    mounted() {
      // console.log("mounted")
      this.updateCurrentLanguage();
      this.updateFontSize();
    },
    beforeUpdate() {
      // console.log("beforeUpdate")
    },
    updated() {
      // console.log("updated")
    },
    methods: {
      async shareDemo(id) {
        try {
          await navigator.clipboard.writeText(window.location.origin + '/#/?id=' + id);
          notify({
              group: "info",
              title: this.isEnglish() ? "Added to Clipboard" : "Zur Zwischenablage hinzugefügt",
              text: this.isEnglish() ? "Link to Event successfully copied to clipboard." : "Link zur Veranstaltung erfolgreich in Zwischenablage kopiert."
            },
            4000
          )
        } catch($e) {
          notify({
              group: "error",
              title: this.isEnglish() ? "Added to Clipboard" : "Zur Zwischenablage hinzugefügt",
              text: this.isEnglish() ? "Link could not be copied to clipboard." : "Demo konnte nicht kopiert werden."
            },
            4000
          )
        }
      },
      getSearchUrl() {
        return location.origin + `/#/?lookfor=true`
            + `&lat=` + encodeURIComponent(this.lat)
            + `&lng=` + encodeURIComponent(this.lng)
            + `&city=` + (encodeURIComponent(this.address.city || ""))
            + `&postcode=` + (encodeURIComponent(this.address.postcode || ""))
            + `&topic=` + encodeURIComponent(this.topic)
            + `&startDate=` + encodeURIComponent(this.startDate)
            + `&endDate=` + encodeURIComponent(this.endDate)
            + `&radius=` + encodeURIComponent(this.radius)
      },
      async shareSearch() {
        try {
          if (this.address.postcode == undefined) {
            this.address.postcode = '';
          }
          if (this.address.city == undefined) {
            this.address.city = '';
          }
          await navigator.clipboard.writeText(this.getSearchUrl());
          notify({
              group: "info",
              title: this.isEnglish() ? "Added to Clipboard" : "Zur Zwischenablage hinzugefügt",
              text: this.isEnglish() ? "Link to search successfully copied to clipboard." : "Link zur Suche erfolgreich in Zwischenablage kopiert."
            },
            4000
          )
        } catch($e) {
          notify({
              group: "error",
              title: this.isEnglish() ? "Added to Clipboard" : "Zur Zwischenablage hinzugefügt",
              text: this.isEnglish() ? "Link could not be copied to clipboard." : "Demo konnte nicht kopiert werden."
            },
            4000
          )
        }
      },
      sortTable(col) {
        if (this.sortColumn === col) {
          this.ascending = !this.ascending;
        } else {
          this.ascending = true;
          this.sortColumn = col
        }

        var ascending = this.ascending

        if (col == 'start_date') {
          return this.sortdemosByStartTime(this.demos, ascending);
        }

        this.demos.sort(function(a, b) {
          if (a[col] > b[col]) {
            return ascending ? 1 : -1
          } else if (a[col] < b[col]) {
            return ascending ? -1 : 1
          }
          return 0;
        })
      },
      clickDemoOnList(demo) {
        this.selectDemo = demo
        this.$selectDemo.value = demo;
      },
      updateCurrentLanguage(){
        this.currentLanguage = this.$currentLanguage.value
      },
      updateFontSize(){
        this.fontSize = this.$fontSize.value
      },
      isDarkMode() {
        return this.$userTheme.value === 'dark-theme';
      },
      isLargeFont() {
        return this.$fontSize.value === '1.3rem';
      },
      handleSelectDemo(data) {
        this.selectDemo = data;
        if (!this.$isMobile() && this.isListViewVisible && window.innerWidth > window.innerHeight) {
          document.getElementById(data.id).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest'});
        }
        // notify({
        //   group: "info",
        //   title: "Daten zur Veranstaltung",
        //   text: ""
        // }, 10000)
      },
      async fetchCoordsAndAddress() {
        try {
          this.setLoadingLocationState();
          let coords = await locationService.currentCoordinates();
          this.lat = coords.latitude;
          this.lng = coords.longitude;
          this.address = await locationService.addressByCoordinates(coords);
          this.locationFound = true;
          // Reset the loading state after fetching the address.
        } catch (error) {
          this.setErrorState(error);
        } finally {
          this.isLoadingCurrentLocation = false;
        }
      },
      clearAddress() {
        this.lat = null;
        this.lng = null;
        this.address = {};
        this.isLoadingCurrentLocation = false;
        this.locationFound = false;
      },
      async getCoords() {
        const params = {"city": this.address.city, "postalcode": this.address.postcode /*, "housenumber": this.address.house_number, "street": this.address.road */};
        let coords = await locationService.coordinatesByAddress(params);
        if (coords.length) {
          this.lat = +coords[0].lat;
          this.lng = +coords[0].lon;
          this.locationFound = true;
          return true;
        } else {
          // console.log("second getCoords try with postal only");
          const params = {"postalcode": this.address.postcode/* , "city": this.address.city, "housenumber": this.address.house_number, "street": this.address.road */};
          let coords = await locationService.coordinatesByAddress(params);
          if (coords.length) {
            this.lat = +coords[0].lat;
            this.lng = +coords[0].lon;
            this.locationFound = true;
            return true;
          }
        }
        return false;
      },
      setErrorState(error) {
        this.error = error;
        this.isLoadingCurrentLocation = false;
      },
      setLoadingLocationState() {
        this.error = null;
        this.isLoadingCurrentLocation = true;
      },
      getDomainWithoutSubdomain() {
        const urlParts = new URL(location.origin).hostname.split('.')

        return urlParts
          .slice(0)
          .slice(-(urlParts.length === 4 ? 3 : 2))
          .join('.')
      },
      async findDemosCloseBy(e) {
        if (e != null) {
          e.preventDefault();
        }
        if ((!this.address.postcode && !this.address.city) || !(await this.getCoords())) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid address" : "Ungültige Adresse",
            text: this.isEnglish() ? "Please indicate place or postal." : "Bitte Ort oder Postleitzahl angeben."
          }, 4000)
          return;
        }

        if (this.startDate > this.endDate) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid date" : "Ungültiges Datum",
            text: this.isEnglish() ? "'To' cannot be before 'From'" : "'Bis' darf nicht vor 'Vom' liegen."
          }, 4000)
          return;
        }
   
        this.isLoadingDemos = true;
        this.lastSearch = encodeURI(`https://demo-alarm.de/api2/public/index.php/demos.json?latitude=${this.lat}&longitude=${this.lng}&topic=${this.topic.trim()}&start_date[after]=${this.startDate}&end_date[before]=${this.endDate}&radius=${this.radius * 1000}`);
        if (location.origin.includes('localhost')) {
          this.lastSearch = encodeURI(`http://localhost:8085/api2/public/index.php/demos.json?latitude=${this.lat}&longitude=${this.lng}&topic=${this.topic.trim()}&start_date[after]=${this.startDate}&end_date[before]=${this.endDate}&radius=${this.radius * 1000}`);
        }
        axios.get(this.lastSearch, {timeout: 10000}).then(response => {
          if (response.data.length == 0) {
            notify({
              group: "info",
              title: this.isEnglish() ? "No results " : "Keine Ergebnisse ",
              text: this.isEnglish() ? "No events found for the specified period and area." : "Keine Veranstaltungen für den angegebenen Zeitraum und Bereich gefunden."
            }, 4000)
            this.searchDemoResult = []
            this.selectDemo = null;
            this.demos = [];
            this.data = [];
            this.isMapLoaded = true;
            this.isLoadingDemos = false;
            return;
          }
          response.data.forEach((demo) => {
            demo.color = this.getDemoColor(demo.topic);
            demo.start_time_english = this.getEnglishTime(demo.start_time);
            demo.end_time_english = this.getEnglishTime(demo.end_time);
          });
          if (this.sortColumn === 'start_date') {
            this.demos = this.sortdemosByStartTime(response.data, this.ascending);
          } else {
            this.demos = response.data;
            this.ascending = !this.ascending;
            this.sortTable(this.sortColumn)
          }
          let groupedDemos = this.groupByKey(response.data, 'topic'); // group data for map
          let mapDemosNumeric = [];
          for (var scalarDemoKey in groupedDemos) {
            mapDemosNumeric.push(groupedDemos[scalarDemoKey]);
          }
          this.searchDemoResult = mapDemosNumeric;
          this.isSearchFormCollapsed = true;
          if (!this.$isMobile()) {
            this.isListViewVisible = true;
          }
          this.isMapLoaded = true;
          this.isMapVisible = true;
          let text = ''
          if (this.demos.length == 1) {
            text = this.isEnglish() ? 'One event takes place.' : 'Es findet eine Veranstaltung statt.';
          }
          if (this.demos.length >= 2) {
            text = this.isEnglish() ? this.demos.length + ' ' + ' events found.' :  this.demos.length + ' Veranstaltungen wurden gefunden.';
          }
          notify({
            group: "info",
            title: text
          }, 4000)
        }).catch(error => {
          // console.log(error.message);
          notify({
            group: "error",
            title: "Fehler beim Abrufen der Veranstaltungen.",
            text: error.message
          }, 4000)
        }).finally(() => {
          this.isLoadingDemos = false;
        });
      },
      async deleteDemoById(id) {
        let URL = `https://demo-alarm.de/api2/public/index.php/demos/${id}`;
        if (location.origin.includes('localhost')) {
          URL = `http://localhost:8085/api2/public/index.php/demos/${id}`;
        }
        const headers = { 
          'Cookie': document.cookie,
          "Content-Type": "application/json; charset=UTF-8",
          // 'Access-Control-Allow-Origin': '*',
          // 'Access-Control-Allow-Credentials': 'true',
        };

        axios.defaults.withCredentials = true;
        let retval = await axios.delete(URL, { headers }).then(response => {
          if (response.data.length == 0) {
            notify({
              group: "info",
              title: this.isEnglish() ? "Deletion successful" : "Löschen erfolgreich",
              text: this.isEnglish() ? "Event was deleted." : "Veranstaltung erfolgreich gelöscht"
            }, 4000)
          }
          return true;
        })
        .catch(error => {
          if (error.response) {
            return error;
          }
        });
        axios.defaults.withCredentials = false;
        if (retval === true) {
          let newUrl = this.getSearchUrl();
          window.location.href = newUrl;
          setTimeout(
            function() {
              window.location.reload();
            }, 2000
          );
        } else if (retval.response) {
            notify({
              group: "error",
              title: this.isEnglish() ? "Deletion error" : "Fehler beim Löschen",
              text: retval.response.statusText
            }, 4000)
        }
      },
      async findDemoById(id) {
        this.isLoadingDemos = true;
        let URL = `https://demo-alarm.de/api2/public/index.php/demos/${id}.json`;
        if (location.origin.includes('localhost')) {
          URL = `http://localhost:8085/api2/public/index.php/demos/${id}.json`;
        }
        axios.get(URL, { timeout: 10000 }).then(response => {
          if (response.data.length == 0) {
            notify({
              group: "info",
              title: this.isEnglish() ? "No results " : "Keine Ergebnisse ",
              text: this.isEnglish() ? "No events found for the specified period and area." : "Keine Veranstaltungen für den angegebenen Zeitraum und Bereich gefunden."
            }, 4000)
            this.searchDemoResult = []
            this.selectDemo = null;
            this.demos = [];
            this.data = [];
            this.isMapLoaded = true;
            this.isLoadingDemos = false;
            return;
          }
          let demo = response.data;
          demo.color = this.getDemoColor(demo.topic);
          demo.start_time_english = this.getEnglishTime(demo.start_time);
          demo.end_time_english = this.getEnglishTime(demo.end_time);
          this.searchDemoResult = [demo];
          this.isSearchFormCollapsed = true;
          this.isMapLoaded = true;
          this.demos = [demo];
          if (!this.$isMobile()) {
            this.isListViewVisible = true;
          }
          this.isMapVisible = true;
        }).catch(error => {
          // console.log(error.message);
          notify({
            group: "error",
            title: "Fehler beim Abrufen der Veranstaltung.",
            text: error.message
          }, 4000)
        }).finally(() => {
          this.isLoadingDemos = false;
        });
      },
      groupByKey(array, key) {
        // Return the end result
        return array.reduce((result, currentValue) => {
          if (result[currentValue[key]]) {
            result[currentValue[key]].count = result[currentValue[key]].count + 1;
          } else {
            result[currentValue[key]] = currentValue;
            result[currentValue[key]].count = 1;
          }
          return result;
        }, []);
      },
      getEnglishTime(de) {
        let time = de.split(':');
        switch (time[0]) { // hour
          case '00':
            return '12:' + time[1] + ' am'
          case '01':
            return '1:' + time[1] + ' am'
          case '02':
            return '2:' + time[1] + ' am'
          case '03':
            return '3:' + time[1] + ' am'
          case '04':
            return '4:' + time[1] + ' am'
          case '05':
            return '5:' + time[1] + ' am'
          case '06':
            return '6:' + time[1] + ' am'
          case '07':
            return '7:' + time[1] + ' am'
          case '08':
            return '8:' + time[1] + ' am'
          case '09':
            return '9:' + time[1] + ' am'
          case '10':
            return '10:' + time[1] + ' am'
          case '11':
            return '11:' + time[1] + ' am'
          case '12':
            return '12:' + time[1] + ' pm'
          case '13':
            return '1:' + time[1] + ' pm'
          case '14':
            return '2:' + time[1] + ' pm'
          case '15':
            return '3:' + time[1] + ' pm'
          case '16':
            return '4:' + time[1] + ' pm'
          case '17':
            return '5:' + time[1] + ' pm'
          case '18':
            return '6:' + time[1] + ' pm'
          case '19':
            return '7:' + time[1] + ' pm'
          case '20':
            return '8:' + time[1] + ' pm'
          case '21':
            return '9:' + time[1] + ' pm'
          case '22':
            return '10:' + time[1] + ' pm'
          case '23':
            return '11:' + time[1] + ' pm'
          default:
            return de + 'am'
        }
      },
      closeAllLists() {
        this.suggestsCity = [];
        this.suggestsPostcode = [];
      },
      keyDownAutocomplete: function keyDownAutocomplete(e) {
        this.locationFound = false;
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
          let type = e.target.getAttribute("id");
          let autocompleteList = document.getElementById(
            "autocomplete-" + type
          );
          if (autocompleteList) {
            autocompleteList = autocompleteList.getElementsByTagName("div");
            if (autocompleteList.length === 0) {
              // if (e.key === 'Enter') {
              //   this.$refs.submit.click();
              // }
              return;
            }
          }
          if (e.key === 'ArrowDown') {
            this.currentFocus++;
            this.addActive(autocompleteList);
          } else if (e.key === 'ArrowUp') {
            //up
            this.currentFocus--;
            this.addActive(autocompleteList);
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (this.currentFocus > -1) {
              if (autocompleteList) autocompleteList[this.currentFocus].click();
            }
          }
        }
      },
      keyUpAutocomplete: function keyUpAutocomplete(e) {
        this.locationFound = false;
        if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Enter') {
          this.autocompleteCallback(e, e.target.id);
        }
      },
      keyUpStartDate: function keyUpStartDate() {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      },
      async autocompleteCallback(inputEvent, searchType = "default") {
        this.closeAllLists();
        let fieldVal = inputEvent.target.value;
        if (fieldVal == null || fieldVal.length < 3) {
          return false;
        }
        let suggests = await locationService.autocomplete(fieldVal, searchType);
        if (searchType === "postcode") {
          for (let i in suggests.data) {
            this.suggestsPostcode.push({'label': i, 'data':suggests.data[i]})
          }
        } else {
          for (let i in suggests.data) {
            this.suggestsCity.push({'label': i, 'data':suggests.data[i]})
          }
        }

        this.currentFocus = -1;
      },
      addActive(autocompleteList) {
        if (!autocompleteList) return false;
        this.removeActive(autocompleteList);
        if (this.currentFocus >= autocompleteList.length) this.currentFocus = 0;
        if (this.currentFocus < 0) this.currentFocus = autocompleteList.length - 1;
        autocompleteList[this.currentFocus].classList.add("autocomplete-active");
      },
      removeActive(autocompleteList) {
        for (let i = 0; i < autocompleteList.length; i++) {
          autocompleteList[i].classList.remove("autocomplete-active");
        }
      },
      selectCity(event) {
        let index = event.target.getElementsByClassName("index")[0].innerText;
        this.address.city = this.suggestsCity[index].data.suggest;
        this.address.postcode = '';
        this.lat = +this.suggestsCity[index].data.lat;
        this.lng = +this.suggestsCity[index].data.lon;
        // this.locationFound = true;
        this.closeAllLists();
      },
      selectPostcode(event) {
        let index = event.target.getElementsByClassName("index")[0].innerText;
        this.address.city = '';
        this.address.postcode = this.suggestsPostcode[index].data.suggest;
        this.lat = +this.suggestsPostcode[index].data.lat;
        this.lng = +this.suggestsPostcode[index].data.lon;
        // this.locationFound = true;
        this.closeAllLists();
      },
      toggleSearchForm() {
        if (this.isSearchFormCollapsed) {
          this.isListViewVisible = false;
        }
        this.isSearchFormCollapsed = !this.isSearchFormCollapsed;
        this.repaint += 1;
        document.getElementById('home').scrollIntoView({ behavior: 'smooth', block: 'start' });
      },
      collapseSearchForm() {
        this.isSearchFormCollapsed = true;
      },
      splitLongStringToMultipleLines(string) {
        // https://stackoverflow.com/questions/31604471/break-long-string-variable-in-multiple-lines-php
        if (!string || string.length < 30)
          return
        
        let maxLineLength = 40
        let maxLineNumber = 2
        let stringArray = string.split(' ')
        let arrayOutput = []
        arrayOutput[0] = ''
        let currentLength = 0
        let index = 0
        let shouldSkip = false

        stringArray.forEach((word) => {
          if (shouldSkip)
            return
          
          let wordLength = word.length + 1
          if((currentLength + wordLength ) <= maxLineLength)
          {
              arrayOutput[index] += word + ' '
              currentLength += wordLength
          }
          else
          {
              if (index + 1 == maxLineNumber) {
                arrayOutput[index] += '...'
                shouldSkip = true
                return
              }
              index += 1
              currentLength = wordLength
              arrayOutput[index] = word + ' '
          }
        })

        return arrayOutput.join('\n')
      },
      getDemoColor(demo) {
        let demoLowerCase = demo.toLowerCase();
        if (demoLowerCase.includes('corona') || demoLowerCase.includes('versammlungsfreiheit') || demoLowerCase.includes('andemie') || demoLowerCase.includes('who') || demoLowerCase.includes('Tegel geht auf') || demoLowerCase.includes('freien geister') || demoLowerCase.includes('rki') || demoLowerCase.includes('impf') || demoLowerCase.includes('schildern') || demoLowerCase.includes('selbstbestimm') || demoLowerCase.includes('querdenken') || demoLowerCase.includes('demokratische werte') || demoLowerCase.includes('steht auf') || demoLowerCase.includes('frieden mit russland') || demoLowerCase.includes('sars-cov-2') || demoLowerCase.includes('postvac') || demoLowerCase.includes('freie')) {
          return "blue";
        }
        if (demoLowerCase.includes('militarismus') || demoLowerCase.includes('energiepreisen') || demoLowerCase.includes('Revolution') || demoLowerCase.includes('schmutzige gelder') || demoLowerCase.includes('progrom') || demoLowerCase.includes('internationale solid') || demoLowerCase.includes('us-krieg') || demoLowerCase.includes('armut') || demoLowerCase.includes('kriegshetze gegen russland') || demoLowerCase.includes('20. juli') || demoLowerCase.includes('befreiung') || demoLowerCase.includes('frieden') || demoLowerCase.includes('faschis') || demoLowerCase.includes('us-krieg') || demoLowerCase.includes('fremdherrschaft') || demoLowerCase.includes('kriegspolitik') || demoLowerCase.includes('preise deckeln')) {
          return "red";
        }
        if (demoLowerCase.includes('putin') || demoLowerCase.includes('nawalny') || demoLowerCase.includes('fridays') || demoLowerCase.includes('verkehrswende') || demoLowerCase.includes('animal') || demoLowerCase.includes('bio') || demoLowerCase.includes('klima') || demoLowerCase.includes('grün') || demoLowerCase.includes('tempo') || demoLowerCase.includes('radf') || demoLowerCase.includes('energie') || demoLowerCase.includes('atom') || demoLowerCase.includes('tierindustrie')) {
          return "green";
        }
        if (demoLowerCase.includes('lesben') || demoLowerCase.includes('transfeindlichkeit') || demoLowerCase.includes('trans* day') || demoLowerCase.includes('lgbt') || demoLowerCase.includes('christopher') || demoLowerCase.includes('csd') || demoLowerCase.includes('queer') || demoLowerCase.includes('pride')) {
          return "rainbow";
        }
        // default black
        return "black";
      },
      sortdemosByStartTime: function (inputdemos, ascending) {
        return inputdemos.sort(function(a, b){
          return ((a.start_timestamp > b.start_timestamp) ? 1 : -1) * (ascending ? 1 : -1);
        });
      },
      isEnglish() {
          return this.$currentLanguage.value == 'en';
      },
      resizableTableHandler(data) {
        this.resizeGrid(data);
      },
      resizableMountTableHandler(data) {
        this.resizeGrid(data);
      },
      resizableEndTableHandler(data) {
        this.resizeGrid(data);
        this.repaint += 1;
      },
      resizeGrid(data) {
        if (this.$isMobile()) {
          return;
        }
        let newSidebarWidth = data.width + 10;
        if (this.isMapVisible) {
          document.getElementById('home').setAttribute("style", "grid-template-columns: " + newSidebarWidth + "px auto 1rem;");
        }
        document.getElementsByClassName('resizable-content')[0].style.width = "100%"
      },
      toggleList() {
        this.isListViewVisible = !this.isListViewVisible;
        document.getElementById('navbar').scrollIntoView({ behavior: 'smooth', block: 'start' });
        if (this.isMapVisible) {
          this.repaint += 1;
        } else {
          this.repaint = 0;
        }
      },
      toggleMap() {
        this.isMapVisible = !this.isMapVisible;
        if (this.$isMobile())
          return;
        
        if (this.isMapVisible) {
          if (this.isListViewVisible) {
            document.getElementById('home').setAttribute("style", "grid-template-columns: " + (document.getElementById('home').offsetWidth / 2) + "px auto 1rem;");
            document.getElementsByClassName('resizable-content')[0].style.width = (document.getElementById('home').offsetWidth / 2) + 'px';
            document.getElementsByClassName('resizable-component')[0].style.width = (document.getElementById('home').offsetWidth / 2) + 'px';
          } else {
            document.getElementById('home').setAttribute("style", "grid-template-columns: " + (document.getElementById('home').offsetWidth) + "px auto 1rem;");
          }
        } else {
          document.getElementById('home').setAttribute("style", "grid-template-columns: " + document.getElementById('home').offsetWidth + "px auto 1rem;");
          if (document.getElementsByClassName('resizable-content')[0]) {
            document.getElementsByClassName('resizable-content')[0].style.width = document.getElementById('home').offsetWidth + 'px';
          }
        }
      },
      /*
      getArrow() {
        return '<svg viewbox="0 0 100 200" width="100" height="20px">' +
          '<polyline points = "20 50, 50 20, 80 50" style="stroke:rgb(255,255,255);stroke-width:3" />' +
          '<line x1="50" y1="20" x2="50" y2="180" style="stroke:rgb(255,255,255);stroke-width:3" />' +
          '<polyline points = "20 150, 50 180, 80 150" style="stroke:rgb(255,255,255);stroke-width:3" />' +
          '</svg > '
      }*/
    }
  }

  window.addEventListener('orientationchange', function () {
    var originalBodyStyle = getComputedStyle(document.body).getPropertyValue('display');
    document.body.style.display='none';
    setTimeout(function () {
      document.body.style.display = originalBodyStyle;
    }, 10);
  });
</script>
<style scoped>
#map_container {
  background: #44475C;
  border: 2px solid var(--accent-color);
  margin: 0;
  padding: 0;
}
#map_container a {
  color: #6435c9 !important;
}
.loading {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0px;
  overflow: hidden;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 999;
}

#sidebar {
  background-color: var(--background-color-primary);
}

.loader {
  animation: spin 1s linear infinite;
  font-size: 10px;
  border-bottom: 2em solid rgba(218, 219, 223, 1);
  border-left: 2em solid #5ad67d;
  border-right: 2em solid rgba(218, 219, 223, 1);
  border-top: 2em solid rgba(218, 219, 223, 1);
}

.loader,
.loader:after {
  border-radius: 50%;
  height: 15em;
  left: 50%;
  margin-left: -7.5em;
  margin-top: -7.5em;
  position: fixed;
  top: 50%;
  width: 15em;
  z-index: 999;
}

.arrow_down {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAaCAYAAABPY4eKAAAAAXNSR0IArs4c6QAAAvlJREFUSA29Vk1PGlEUHQaiiewslpUJiyYs2yb9AyRuJGm7c0VJoFXSX9A0sSZN04ULF12YEBQDhMCuSZOm1FhTiLY2Rky0QPlQBLRUsICoIN/0PCsGyox26NC3eTNn3r3n3TvnvvsE1PkwGo3yUqkkEQqFgw2Mz7lWqwng7ztN06mxsTEv8U0Aam5u7r5EInkplUol/f391wAJCc7nEAgE9Uwmkzo4OPiJMa1Wq6cFs7Ozt0H6RqlUDmJXfPIx+qrX69Ti4mIyHA5r6Wq1egND+j+IyW6QAUoul18XiUTDNHaSyGazKcZtdgk8wqhUKh9o/OMvsVgsfHJy0iWqVrcQNRUMBnd6enqc9MjISAmRP3e73T9al3XnbWNjIw2+KY1Gc3imsNHR0YV4PP5+d3e32h3K316TySQFoX2WyWR2glzIO5fLTSD6IElLNwbqnFpbWyO/96lCoai0cZjN5kfYQAYi5H34fL6cxWIZbya9iJyAhULBHAqFVlMpfsV/fHxMeb3er+Vy+VUzeduzwWC45XA4dlD/vEXvdDrj8DvURsYEWK3WF4FA4JQP9mg0WrHZbEYmnpa0NxYgPVObm5teiLABdTQT8a6vrwdRWhOcHMzMzCiXlpb2/yV6qDttMpkeshEzRk4Wo/bfoe4X9vb2amzGl+HoXNT29vZqsVi0sK1jJScG+Xx+HGkL4Tew2TPi5zUdQQt9otPpuBk3e0TaHmMDh1zS7/f780S0zX6Yni+NnBj09fUZUfvudDrNZN+GkQbl8Xi8RLRtHzsB9Hr9nfn5+SjSeWUCXC7XPq5kw53wsNogjZNohYXL2EljstvtrAL70/mVaW8Y4OidRO1/gwgbUMvcqGmcDc9aPvD1gnTeQ+0nmaInokRj0nHh+uvIiVOtVvt2a2vLv7Ky0tL3cRTXIcpPAwMDpq6R4/JXE4vFQ5FI5CN+QTaRSFCYc8vLy1l0rge4ARe5kJ/d27kYkLXoy2Jo4C7K8CZOsEBvb+9rlUp1xNXPL7v3IDwxvPD6AAAAAElFTkSuQmCC')
}
.arrow_up {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAaCAYAAACgoey0AAAAAXNSR0IArs4c6QAAAwpJREFUSA21Vt1PUmEYP4dvkQ8JFMwtBRocWAkDbiqXrUWXzU1rrTt0bdVqXbb1tbW16C9IBUSmm27cODdneoXjputa6069qwuW6IIBIdLvdaF4OAcOiGeDc87zPs/vd57P96WpFq7p6enbGo1mjKZpeTabjU1MTCRagGnOZHFxcXxtbe1XKpUq7+zslJeXl//Mz8+Hy+Uy3RxSE9qTk5M3otFooVQqgef4Wl9f343FYoEmoISrxuNxFX5f9vb2jhn/PxUKhfLS0tIPfFifUESRUMV8Pv/M6XReRm5rTGQyGeXxeGxYe1ezeBpBOBx2rKysbO7v79d4Wy3Y2Nj4GQqFbgnhaugxwiuGJx99Pp9FLBbXxYTXvTqd7v3MzIy6riIWGxJnMpl7AwMD14xGYyMsSq1WUyQdUqn0eSPlusQIsbGrq+vl4OCgvhFQZd1utyv1en0gEolcqsi47nWJlUrlG5fLZVcoFFy2nDKSDpIWlUoVTCQSEk4lCHmJMZ2GTCbTiMVikfIZ88l7enoos9l8dXt7+z6fDicxSJUokqDX6xXcl2wCROoc0vQCWL3sNfLOSdzR0fHY4XC4tVotl40gmVwup9xuN4OQv+UyqCFGH9rg7SOGYVRcBs3IEG4J0nVnamrqOtvuBDGGgQg9+wHFcVEi4a0LNkbdd6TrPKo8ODc311mteIIYjT/a398/jK+s1jnVM0kXoufCFvq0GuiIGEVgQIhfoygM1QrteEa9dAL7ITiYCt4RMabOK5AyKKzKWtvupLcRciu8D5J0EuDDPyT/Snd39yh6VtY2NhYQSR9G79Ds7OxdskRjEyAufvb7/cPoO5Z6e1+xtVKrq6vfcFzyi/A3ZrPZ3GdNSlwgo5ekE4X2RIQGf2C1WlufFE0GBeGWYQ8YERWLxQtnUVB830MKLZfL9RHir8lkssCn2G751tZWEWe03zTKm15YWPiEiXXTYDB0Ig/t7yd8PRws4EicwWHxO4jHD8/C5HiTTqd1BwcHFozKU89origB+y/kmzgYpgOBQP4fGmUiZmJ+WNgAAAAASUVORK5CYII=')
}
.arrow {
  height: 18px;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  float: right;
  margin-right: 1rem;
  width: 12px;
}
.arrow.left {
  float: left;
}
.resizable-content {
  border-right: 3px double purple;
  height: 100%;
  overflow: auto;
  width: 99.5%;
}

@media only screen and (max-width: 500px) {
  #resultTable {
    display: none;
  }
}

@media (min-width: 501px) {
    #resultTableMobile {
      display: none;
    }
}
</style>