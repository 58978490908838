/* eslint-disable */
<template>
  <div
    v-if="isLoadingCurrentLocation"
    class="loading"
  >
    <div class="loader" />
  </div>
  <div
    id="home"
    role="main"
    class="grid grid-cols-1 relative"
    @click="closeAllLists"
  >
    <h1
      role="heading"
      class="text-2xl pl-2 pt-2 pb-2"
    >
      {{ isEnglish() ? 'Report new event' : 'Neue Veranstaltung melden' }}
    </h1>
    <div
      id="sidebar"
      class="z-40"
    >
      <div class="max-w-3xl ml-1 mb-1">
        <form
          class="ui segment large form"
          autocomplete="new-password"
        >
          <div class="field">
            <div class="three fields">
              <div class="field sixteen wide column">
                <label
                  for="topic"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ topicLabel }}</label>
                <input
                  id="topic"
                  v-model="topic"
                  name="topic"
                  autocomplete="new-password"
                  :placeholder="topicPlaceholder"
                >
              </div>
            </div>
          </div>
          <div class="field">
            <div class="three fields">
              <div class="field eight wide column">
                <label
                  class="required"
                  for="startDate"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ fromLabel }}</label>
                <input
                  id="startDate"
                  v-model="startDate"
                  type="datetime-local"
                  name="start_Date"
                  :placeholder="datePlaceholder"
                  :title="fromAria"
                  @keyup="keyUpStartDate"
                >
              </div>
              <div class="field eight wide column">
                <label
                  class="required"
                  for="endDate"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ toLabel }}</label>
                <!--select
                  id="type"
                  v-model="type"
                >
                  <option value="restaurant">
                    Restaurant
                  </option>
                </!--select-->
                <input
                  id="endDate"
                  v-model="endDate"
                  type="datetime-local"
                  name="end_date"
                  :title="toAria"
                  :placeholder="datePlaceholder"
                  @keyup="keyUpEndDate"
                >
              </div>
            </div>
          </div>
          <div class="field">
            <div class="two fields">
              <!--div class="field eleven wide column">
                <label
                  for="city"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ placeLabel }}</label>
                <input
                  id="city"
                  v-model="address.city"
                  name="city"
                  autocomplete="new-password"
                  :placeholder="placePlaceholder"
                  @keydown="keyDownAutocomplete"
                  @keyup="keyUpAutocomplete"
                  @keyup.enter="sendReport"
                >
                <div
                  id="autocomplete-city"
                >
                  <div
                    v-for="(suggest, index) in suggestsCity"
                    :key="index"
                    class="autocomplete-address"
                    @click="selectCity"
                  >
                    <span
                      class="index"
                      hidden
                    >{{ index }}</span>
                    {{ suggest.label }}
                  </div>
                </div>
              </div-->
              <div class="field five wide column">
                <label
                  for="postcode"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ postalLabel }}</label>
                <input
                  id="postcode"
                  v-model="address.postcode"
                  name="postcode"
                  pattern="[0-9]+"
                  :placeholder="postalPlaceholder"
                  autocomplete="new-password"
                  @keydown="keyDownAutocomplete"
                  @keyup="keyUpAutocomplete"
                  @keyup.enter="sendReport"
                >
                <div
                  id="autocomplete-postcode"
                >
                  <div
                    v-for="(suggest, index) in suggestsPostcode"
                    :key="index"
                    class="autocomplete-address"
                    @click="selectPostcode"
                  >
                    <span
                      class="index"
                      hidden
                    >{{ index }}</span>
                    {{ suggest.label }}
                  </div>
                </div>
               </div>
            <!--/div>
          </div>
          <div class="field">
            <div class="two fields" -->
              <div class="field eleven wide column">
                <label
                  for="street"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ streetLabel }}</label>
                <input
                  id="street"
                  v-model="address.street"
                  name="street"
                  autocomplete="new-password"
                  :placeholder="streetPlaceholder"
                  @keyup.enter="sendReport"
                >
              </div>
              <!-- div class="field five wide column">
                <label
                  for="streetNumber"
                  :style="{ color: isDarkMode() ? 'white' : 'black'}"
                >{{ streetNumberLabel }}</label>
                <input
                  id="streetNumber"
                  v-model="address.streetNumber"
                  type="number"
                  min="1"
                  max="9999"
                  name="streetNumber"
                  :placeholder="streetNumberPlaceholder"
                  autocomplete="new-password"
                  @keyup.enter="sendReport"
                >
              </div-->
            </div>
          </div>
          <!--div class="field">
            <div class="two fields">
              <div class="field eleven wide column">
                <label for="street" :style="{ color: isDarkMode() ? 'white' : 'black'}">Straße</label>
                <input
                  id="street"
                  v-model="address.road"
                  name="street"
                  placeholder="Straße"
                  autocomplete="new-password"
                  @keyup.enter="sendReport"
                >
              </div>
              <div class="field five wide column">
                <label for="street_number" :style="{ color: isDarkMode() ? 'white' : 'black'}">HausNr</label>
                <input
                  id="street_number"
                  v-model="address.house_number"
                  name="street_number"
                  placeholder="Hausnummer"
                  autocomplete="new-password"
                  @keyup.enter="sendReport"
                >
              </div>
            </div>
          </div -->
          <div>
            <input
              id="secret"
              name="secret"
              value="6Lcc7VYmAAAAABZQnYJM1Tpi9okdcbpIJsfq3ULs"
              type="hidden"
            >
            <div
              class="frc-captcha"
              data-sitekey="FCMJ47SDDKRMQU16"
              data-callback="myCallback"
            />
          </div>

          <!--div
            class="g-recaptcha"
            data-sitekey="6Lcc7VYmAAAAABZQnYJM1Tpi9okdcbpIJsfq3ULs"
          /-->
          <br>
          <div class="field">
            <div class="ui right icon input large">
              <button
                ref="submit"
                :disabled="isLoadingCurrentLocation || isLoadingDemos"
                class="ui button violet"
                type="button"
                autofocus
                @click="sendReport"
              >
                <i class="search icon" />
                <span>
                  {{ isEnglish() ? 'Submit' : 'Absenden' }}
                </span>
              </button>
              <button
                type="button"
                class="ui button black"
                @click="clearAddress"
              >
                <span>
                  <i class="trash alternate icon" /> {{ isEnglish() ? 'Clear Inputs' : 'Eingaben leeren' }}
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

  // eslint-disable-next-line no-unused-vars
  import axios from "axios"
  import * as locationService from "../services/Location"
  import { notify } from "notiwind"

  export default {
    data() {
      return {
        type: "restaurant",
        radius: "50",
        startDate: new Date().toISOString().slice(0, 11) + '08:00',
        endDate: new Date().toISOString().slice(0, 11) + '12:00',
        topic: '',
        demos: [],
        address: {city: ''},
        suggestsCity: [],
        suggestsPostcode: [],
        currentFocus: -1,
        error: null,
        geolocationSupported: 'geolocation' in navigator,
        locationFound: false,
        isLoadingCurrentLocation: false,
        isLoadingDemos: false,
        handlers: ["r"]
      }
    },
    computed: {
      placeLabel() {
        return this.isEnglish() ? 'Place' : 'Ort'
      },
      placePlaceholder() {
        return this.isEnglish() ? 'Locality' : 'Ortschaft'
      },
      streetLabel() {
        return this.isEnglish() ? 'street and house number' : 'Straße mit Hausummer'
      },
      streetPlaceholder() {
        return this.isEnglish() ? 'street' : 'Straße'
      },
      streetNumberLabel() {
        return this.isEnglish() ? 'number' : 'HausNr'
      },
      streetNumberPlaceholder() {
        return this.isEnglish() ? 'street number' : 'Hausnummer'
      },
      topicPlaceholder() {
        return this.isEnglish() ? 'enter topic and description' : 'Titel und Beschreibung eingeben'
      },
      datePlaceholder() {
        return new Date().toISOString()
      },   
      postalLabel() {
        return this.isEnglish() ? 'Postal' : 'Postleitzahl'
      },
      topicLabel() {
        return this.isEnglish() ? 'Topic' : 'Thema'
      },
      postalPlaceholder() {
        return this.isEnglish() ? 'Zip' : 'PLZ'
      }, 
      fromLabel() {
        return this.isEnglish() ? 'From' : 'Beginn'
      },
      fromAria() {
        return this.isEnglish() ? 'When does the event start?' : 'Wann ist der Beginn der Veranstaltung?'
      },
      toLabel() {
        return this.isEnglish() ? 'To' : 'Ende'
      },
      toAria() {
        return this.isEnglish() ? 'When does the event end?' : 'Wann ist das Ende der Veranstaltung?'
      },
      getFontSize() {
        return this.$fontSize.value;
      }
    },
    watch: {
      '$currentLanguage.value': {
        handler: function () {
            this.updateCurrentLanguage()
        }, deep: true
      },
      '$fontSize.value': {
        handler: function () {
            this.updateFontSize()
        }, deep: true
      }
    },
    beforeCreate() {
      // console.log("before created")
    },
    created() {
      // console.log("created")
      let queryString = window.location.search;
      let urlParams = new URLSearchParams(queryString);
      
      if( urlParams.has('id') ){
        let id = urlParams.get('id');
        this.findDemoById(id)
        return;
      }
    },
    beforeMount() {
      // console.log("beforeMount")
    },
    mounted() {
      // console.log("mounted")
      this.updateCurrentLanguage();
      this.updateFontSize();
      //let recaptchaScript = document.createElement('script')
      //recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      //document.head.appendChild(recaptchaScript)
      let recaptchaScript2 = document.createElement('script')
      recaptchaScript2.setAttribute('src', 'https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.module.min.js')
      document.head.appendChild(recaptchaScript2)
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/friendly-challenge@0.9.12/widget.min.js')
      document.head.appendChild(recaptchaScript)
    },
    beforeUpdate() {
      // console.log("beforeUpdate")
    },
    updated() {
      // console.log("updated")
    },
    methods: {
      updateCurrentLanguage(){
        this.currentLanguage = this.$currentLanguage.value
      },
      updateFontSize(){
        this.fontSize = this.$fontSize.value
      },
      isDarkMode() {
        return this.$userTheme.value === 'dark-theme';
      },
      isLargeFont() {
        return this.$fontSize.value === '1.3rem';
      },
      clearAddress() {
        this.address = {};
        this.isLoadingCurrentLocation = false;
        this.locationFound = false;
      },
      async getCoords() {
        const params = {"city": this.address.city, "postalcode": this.address.postcode /*, "housenumber": this.address.house_number, "street": this.address.road */};
        let coords = await locationService.coordinatesByAddress(params);
        if (coords.length) {
          this.lat = +coords[0].lat;
          this.lng = +coords[0].lon;
          this.locationFound = true;
          return true;
        } else {
          // console.log("second getCoords try with postal only");
          const params = {"postalcode": this.address.postcode/* , "city": this.address.city, "housenumber": this.address.house_number, "street": this.address.road */};
          let coords = await locationService.coordinatesByAddress(params);
          if (coords.length) {
            this.lat = +coords[0].lat;
            this.lng = +coords[0].lon;
            this.locationFound = true;
            return true;
          }
        }
        return false;
      },
      setErrorState(error) {
        this.error = error;
        this.isLoadingCurrentLocation = false;
      },
      setLoadingLocationState() {
        this.error = null;
        this.isLoadingCurrentLocation = true;
      },
      closeAllLists() {
        this.suggestsCity = [];
        this.suggestsPostcode = [];
      },
      keyDownAutocomplete: function keyDownAutocomplete(e) {
        this.locationFound = false;
        if (e.key === 'ArrowDown' || e.key === 'ArrowUp' || e.key === 'Enter') {
          let type = e.target.getAttribute("id");
          let autocompleteList = document.getElementById(
            "autocomplete-" + type
          );
          if (autocompleteList) {
            autocompleteList = autocompleteList.getElementsByTagName("div");
            if (autocompleteList.length === 0) {
              if (e.key === 'Enter') {
                this.$refs.submit.click();
              }
              return;
            }
          }
          if (e.key === 'ArrowDown') {
            this.currentFocus++;
            this.addActive(autocompleteList);
          } else if (e.key === 'ArrowUp') {
            //up
            this.currentFocus--;
            this.addActive(autocompleteList);
          } else if (e.key === 'Enter') {
            e.preventDefault();
            if (this.currentFocus > -1) {
              if (autocompleteList) autocompleteList[this.currentFocus].click();
            }
          }
        }
      },
      keyUpAutocomplete: function keyUpAutocomplete(e) {
        this.locationFound = false;
        if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp' && e.key !== 'Enter') {
          this.autocompleteCallback(e, e.target.id);
        }
      },
      keyUpStartDate: function keyUpStartDate() {
        if (this.startDate > this.endDate) {
          this.endDate = this.startDate;
        }
      },
      keyUpEndDate: function keyUpEndDate() {
        if (this.endDate < this.startDate) {
          this.startDate = this.endDate;
        }
      },
      async autocompleteCallback(inputEvent, searchType = "default") {
        this.closeAllLists();
        let fieldVal = inputEvent.target.value;
        if (fieldVal == null || fieldVal.length < 3) {
          return false;
        }
        let suggests = await locationService.autocomplete(fieldVal, searchType);
        if (searchType === "postcode") {
          for (let i in suggests.data) {
            this.suggestsPostcode.push({'label': i, 'data':suggests.data[i]})
          }
        } else {
          for (let i in suggests.data) {
            this.suggestsCity.push({'label': i, 'data':suggests.data[i]})
          }
        }

        this.currentFocus = -1;
      },
      addActive(autocompleteList) {
        if (!autocompleteList) return false;
        this.removeActive(autocompleteList);
        if (this.currentFocus >= autocompleteList.length) this.currentFocus = 0;
        if (this.currentFocus < 0) this.currentFocus = autocompleteList.length - 1;
        autocompleteList[this.currentFocus].classList.add("autocomplete-active");
      },
      removeActive(autocompleteList) {
        for (let i = 0; i < autocompleteList.length; i++) {
          autocompleteList[i].classList.remove("autocomplete-active");
        }
      },
      selectCity(event) {
        let index = event.target.getElementsByClassName("index")[0].innerText;
        this.address.city = this.suggestsCity[index].data.suggest;
        // this.address.postcode = '';
        this.lat = +this.suggestsCity[index].data.lat;
        this.lng = +this.suggestsCity[index].data.lon;
        // this.locationFound = true;
        this.closeAllLists();
      },
      selectPostcode(event) {
        let index = event.target.getElementsByClassName("index")[0].innerText;
        // this.address.city = '';
        this.address.postcode = this.suggestsPostcode[index].data.suggest;
        this.lat = +this.suggestsPostcode[index].data.lat;
        this.lng = +this.suggestsPostcode[index].data.lon;
        // this.locationFound = true;
        this.closeAllLists();
      },
      splitLongStringToMultipleLines(string) {
        // https://stackoverflow.com/questions/31604471/break-long-string-variable-in-multiple-lines-php
        if (!string || string.length < 30)
          return
        
        let maxLineLength = 40
        let maxLineNumber = 2
        let stringArray = string.split(' ')
        let arrayOutput = []
        arrayOutput[0] = ''
        let currentLength = 0
        let index = 0
        let shouldSkip = false

        stringArray.forEach((word) => {
          if (shouldSkip)
            return
          
          let wordLength = word.length + 1
          if((currentLength + wordLength ) <= maxLineLength)
          {
              arrayOutput[index] += word + ' '
              currentLength += wordLength
          }
          else
          {
              if (index + 1 == maxLineNumber) {
                arrayOutput[index] += '...'
                shouldSkip = true
                return
              }
              index += 1
              currentLength = wordLength
              arrayOutput[index] = word + ' '
          }
        })

        return arrayOutput.join('\n')
      },
      isEnglish() {
          return this.$currentLanguage.value == 'en';
      },
      async sendReport(e) {
        if (e != null) {
          e.preventDefault();
        }

/*         if (window.grecaptcha.getResponse().length === 0) {
          notify({
            group: "error",
            title: this.isEnglish() ? "Captcha invalid" : "Captcha ungültig.",
            text: this.isEnglish() ? "Please confirm captcha" : "Bitte Captcha bestätigen (Ich bin kein Roboter)."
          }, 4000)
          return;
        } */

        if (!this.address.postcode && !this.address.city) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid address" : "Ungültige Adresse",
            text: this.isEnglish() ? "Please indicate a valid city or postal." : "Bitte eine gültige Postleitzahl oder Ort angeben."
          }, 4000)
          return;
        }

        if (!this.address.street || (!this.address.streetNumber && Number.isInteger(this.address.streetNumber))) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid address" : "Ungültige Adresse",
            text: this.isEnglish() ? "Please indicate a valid street and street number." : "Bitte eine gültige Straße mit Hausnummer angeben."
          }, 4000)
          return;
        }

        if (this.startDate > this.endDate) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid date" : "Ungültiges Datum",
            text: this.isEnglish() ? "'To' cannot be before 'From'" : "'Ende' darf nicht vor dem 'Anfang' liegen."
          }, 4000)
          return;
        }

        if (!this.topic || this.topic.length < 3 || this.topic.length > 9999) {
          notify({
            group: "error",
            title: this.isEnglish() ? "invalid topic" : "Ungültiges Thema",
            text: this.isEnglish() ? "Please enter a topic." : "Bitte geben Sie ein gültiges Thema an."
          }, 4000)
          return;
        }
   
        this.isLoadingDemos = true;
        let origin = location.origin.replace('dresden.', '')
        let URL = origin + `/api2/public/index.php/demos`;
        if (location.origin.includes('localhost')) {
          URL = `http://localhost:8085/api2/public/index.php/demos`;
        }
        let data = {};
        if (this.address.city) {
          data.city = this.address.city;
        }
        if (this.address.postcode) {
          data.postcode = this.address.postcode.trim();
        }
        if (this.address.street) {
          data.street = this.address.street;
        }
        if(this.address.streetNumber) {
          data.streetNumber = this.address.streetNumber;
        }
        if (this.topic) {
          data.topic = this.topic;
        }
        if (this.startDate) {
          data.start_date = this.startDate;
          data.start_time = this.startDate;
        }
        if(this.endDate) {
          data.end_time = this.endDate;
        }
        if (document.cookie !== "") {
          data.isPublished = true;
        } else {
          data.isPublished = false;
        }
        axios.post(URL, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (response.data.length == 0) {
            notify({
              group: "info",
              title: this.isEnglish() ? "Error reporting. " : "Fehler beim Melden. ",
              text: this.isEnglish() ? "" : ""
            }, 4000)
            this.searchDemoResult = []
            this.selectDemo = null;
            this.demos = [];
            this.data = [];
            this.isLoadingDemos = false;
            return;
          } else {
            //console.table(response.data);
            if (response.data.id != undefined) {
              window.location.href = location.origin + "/#/?id=" + response.data.id;
            }
          }
        }).catch(error => {
          if (error.response.status == 409) {
            notify({
              group: "error",
              title: this.isEnglish() ? "Error reporting." : "Fehler beim Melden.",
              text: this.isEnglish() ? "This demo is already reported." : "Diese Demo wurde bereits gemeldet."
            }, 4000)
          } else if (error.response.status == 422) {
            // console.table(error.response);
            if (error.response.data.violations) {
              error.response.data.violations.forEach(violoation => {
              notify({
                group: "error",
                title: this.isEnglish() ? "Invalid input." : "Fehlerhafte Eingabe.",
                text: violoation.message
              }, 4000)
            });
            }
          }
          notify({
              group: "error",
              title: this.isEnglish() ? "Error reporting." : "Fehler beim Melden.",
              text: this.isEnglish() ? "Please check your input data." : "Bitte überprüfen Sie Ihre Eingaben."
            }, 4000)
        }).finally(() => {
          this.isLoadingDemos = false;
        });
      }
    }
  }
</script>
<style scoped>
.frc-content {
  height: 1.5rem !important;
}
.frc-text {
  height: 1.5rem !important;
}
</style>

