/* eslint-disable */
<template>
  <div class="blog-card alt">
    <div class="description full-width text-justify">
      <h1> {{ isEnglish() ? 'PRIVACY POLICY' : 'DATENSCHUTZERKLÄRUNG' }} </h1>
      <br>
      <h2> {{ isEnglish() ? '1. Data protection at a glance' : '1. Datenschutz auf einen Blick' }} </h2><br>
      <h3> {{ isEnglish() ? 'General information' : 'Allgemeine Hinweise' }}</h3>
      <p>
        {{ isEnglish() ? 'The following notes provide a simple overview of what happens to your personal data when you visit this website. Personal data is all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our data protection declaration listed under this text.' : 'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.' }}
      </p>
      <h3>{{ isEnglish() ? 'Data collection on this website' : 'Datenerfassung auf dieser Website' }}</h3>
      <p><strong>{{ isEnglish() ? 'Who is responsible for data collection on this website?' : 'Wer ist verantwortlich für die Datenerfassung auf dieser Website?' }}</strong></p>
      <p>{{ isEnglish() ? 'The data processing on this website is carried out by the website operator. You can find their contact details in the imprint of this website.' : 'Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.' }}</p>
      <p><strong>{{ isEnglish() ? 'How do we collect your data?' : 'Wie erfassen wir Ihre Daten?' }}</strong></p>
      <p>
        {{ isEnglish() ? 'On the one hand, your data is collected when you communicate it to us. This can, for example, be data that you enter in a contact form.' : 'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.' }}
      </p>
      <p>
        {{ isEnglish() ? 'Other data is collected automatically or with your consent by our IT systems when you visit the website. This is primarily technical data (e.g. internet browser, operating system or time of the page view). This data is collected automatically as soon as you enter this website.' : 'Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.' }}
      </p>
      <p><strong>{{ isEnglish() ? 'What do we use your data for?' : 'Wofür nutzen wir Ihre Daten?' }}</strong></p>
      <p>
        {{ isEnglish() ? 'Part of the data is collected to ensure that the website is provided without errors.  Other data can be used to analyze your user behavior.' : 'Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.' }}
      </p>
      <p><strong>{{ isEnglish() ? 'What rights do you have regarding your data?' : 'Welche Rechte haben Sie bezüglich Ihrer Daten?' }}</strong></p>
      <p>{{ isEnglish() ? 'You have the right to receive information about the origin, recipient and purpose of your stored personal data free of charge at any time. You also have the right to request the correction or deletion of this data. If you have given your consent to data processing, you can revoke this consent at any time for the future. You also have the right, under certain circumstances, to request that the processing of your personal data be restricted. You also have the right to lodge a complaint with the competent supervisory authority.' : 'Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.' }}</p>
      <p>{{ isEnglish() ? 'You can contact us at any time at the address given in the imprint if you have any further questions on the subject of data protection.' : 'Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.' }}</p>
      <!--h3>{{ isEnglish() ? 'Analysis tools and third-party tools' : 'Analyse-Tools und Tools von Drittanbietern' }}</!--h3>
  <p>{{ isEnglish() ? 'When you visit this website, your surfing behavior can be statistically evaluated. This is mainly done with cookies and so-called analysis programs.' : 'Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit Cookies und mit sogenannten Analyseprogrammen.' }}</p>
  <p>{{ isEnglish() ? 'Detailed information on these analysis programs can be found in the following data protection declaration.' : 'Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.' }}</p> -->
      <h2>{{ isEnglish() ? '2. Hosting and Content Delivery Networks (CDN)' : '2. Hosting und Content Delivery Networks (CDN)' }}</h2>
      <h3>{{ isEnglish() ? 'External Hosting' : 'Externes Hosting' }}</h3>
      <p>
        {{ isEnglish() ? 'This website is hosted by an external service provider (hoster).  We host our website at Alfahosting.  The provider is Alfahosting GmbH, Edmund-von-Lippmann-Straße 13-15, 06112 Halle (Saale).  When you visit our website, Host Alfahosting collects various log files including your IP addresses.' : 'Diese Website wird bei einem externen Dienstleister gehostet (Hoster). Wir hosten unsere Website bei Alfahosting. Anbieter ist die Alfahosting GmbH, Edmund-von-Lippmann-Straße 13-15, 06112 Halle (Saale). Wenn Sie unsere Website besuchen, erfasst Host Alfahosting verschiedene Logfiles inklusive Ihrer IP-Adressen.' }}
        <br>
        {{ isEnglish() ? 'Details can be found in Alfahosting\'s data protection declaration: https://alfahosting.de/datenschutz/.' : 'Details entnehmen Sie der Datenschutzerklärung von Alfahosting: https://alfahosting.de/datenschutz/.' }}
        {{ isEnglish() ? 'Alfahosting is used on the basis of Article 6 (1) (f) GDPR. We have a legitimate interest in our website being displayed as reliably as possible. If a corresponding consent was requested, the processing takes place exclusively on the basis of Art. 6 Para. 1 lit. a DSGVO and § 25 Para  B. for device fingerprinting) within the meaning of the TTDSG. The consent can be revoked at any time.' : 'Die Verwendung von Alfahosting erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Wir haben ein berechtigtes Interesse an einer möglichst zuverlässigen Darstellung unserer Website. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. für Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.' }}   
      </p>
      <p>{{ isEnglish() ? 'The hoster is used for the purpose of fulfilling the contract with our potential and existing customers (Art. 6 Para. 1 lit. b DSGVO) and in the interest of a secure, fast and efficient provision of our online offer by a professional provider (Art. 6 Para  1 lit. f GDPR). Our hoster will only process your data to the extent that this is necessary to fulfill its performance obligations and will follow our instructions in relation to this data.' : 'Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.' }}</p>
      <p><strong>{{ isEnglish() ? 'Conclusion of a contract for order processing' : 'Abschluss eines Vertrages über Auftragsverarbeitung' }}</strong></p>
      <p>{{ isEnglish() ? 'In order to ensure data protection-compliant processing, we have concluded an order processing contract with our hoster.' : 'Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster geschlossen.' }}</p>
      <h2>{{ isEnglish() ? '3. General information and mandatory information' : '3. Allgemeine Hinweise und Pflichtinformationen' }}</h2>
      <h3>{{ isEnglish() ? 'Data Privacy' : 'Datenschutz' }}</h3>
      <p>{{ isEnglish() ? 'The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this data protection declaration.' : 'Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.' }}</p>
      <p>{{ isEnglish() ? 'If you use this website, various personal data will be collected. Personal data is data with which you can be personally identified. This data protection declaration explains what data we collect and what we use it for. It also explains how and for what purpose this happens.' : 'Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.' }}</p>
      <p>{{ isEnglish() ? 'We would like to point out that data transmission on the Internet (e.g. when communicating by e-mail) can have security gaps. A complete protection of the data against access by third parties is not possible.' : 'Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.' }}</p>
      <h3>{{ isEnglish() ? 'Note on the responsible body' : 'Hinweis zur verantwortlichen Stelle' }}</h3>
      <p>{{ isEnglish() ? 'The responsible body for data processing on this website is:' : 'Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:' }}</p>
      <p>Robert Lange<br> Miltitzer Allee 11<br> 04275 Leipzig</p>
      <p>Telefon: +49 (0) 176 3576 0004<br> E-Mail: robert.lange.81@gmail.com</p>
      <p>{{ isEnglish() ? 'The responsible body is the natural or legal person who, alone or together with others, decides on the purposes and means of processing personal data (e.g. names, e-mail addresses, etc.).' : 'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.' }}</p>
      <h3>{{ isEnglish() ? 'Revocation of your consent to data processing' : 'Widerruf Ihrer Einwilligung zur Datenverarbeitung' }}</h3>
      <p>{{ isEnglish() ? 'Many data processing operations are only possible with your express consent. You can revoke consent that you have already given at any time. An informal message by e-mail to us is sufficient. The legality of the data processing that took place up until the revocation remains unaffected by the revocation.' : 'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.' }}</p>
      <h3>{{ isEnglish() ? 'Right to object to data collection in special cases and to direct advertising (Art. 21 GDPR)' : 'Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)' }}</h3>
      <p>{{ isEnglish() ? 'IF THE DATA PROCESSING ON THE BASIS OF ART. 6 PARA. 1 LIT. E OR F GDPR, YOU HAVE THE RIGHT AT ANY TIME TO OBJECT TO THE PROCESSING OF YOUR PERSONAL DATA ON GROUNDS ARISING FROM YOUR PARTICULAR SITUATION; THIS ALSO APPLIES TO PROFILING BASED ON THESE PROVISIONS. THE RESPECTIVE LEGAL BASIS ON WHICH PROCESSING IS BASED CAN BE FOUND IN THIS DATA PROTECTION DECLARATION. IF YOU OBJECT, WE WILL NO LONGER PROCESS YOUR PERSONAL DATA CONCERNED UNLESS WE CAN DEMONSTRATE COMPELLING LEGITIMATE GROUNDS FOR THE PROCESSING WHICH OVERRIDE YOUR INTERESTS, RIGHTS AND FREEDOMS OR FOR THE ESTABLISHMENT, EXERCISE OR DEFENCE OF LEGAL CLAIMS (OBJECTION PURSUANT TO ARTICLE 21 (1) GDPR).' : 'WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).' }}</p>
      <p>{{ isEnglish() ? 'IF YOUR PERSONAL DATA IS PROCESSED FOR DIRECT MARKETING PURPOSES, YOU HAVE THE RIGHT TO OBJECT AT ANY TIME TO THE PROCESSING OF PERSONAL DATA CONCERNING YOU FOR THE PURPOSE OF SUCH ADVERTISING; THIS ALSO APPLIES TO PROFILING INSOFAR AS IT IS ASSOCIATED WITH SUCH DIRECT ADVERTISING. IF YOU OBJECT, YOUR PERSONAL DATA WILL THEN NO LONGER BE USED FOR THE PURPOSE OF DIRECT MARKETING (OBJECTION PURSUANT TO ART. 21 PARA. 2 GDPR). Right to lodge a complaint with the competent supervisory authority In the event of breaches of the GDPR, data subjects have the right to lodge a complaint with a supervisory authority, in particular in the Member State of their habitual residence, place of work or place of the alleged infringement. The right to lodge a complaint is without prejudice to any other administrative or judicial remedy.' : 'WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p><h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3><p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.' }}</p>
      <h3>{{ isEnglish() ? 'Right to data portability' : 'Recht auf Datenübertragbarkeit' }}</h3>
      <p>{{ isEnglish() ? 'You have the right to have data that we process automatically on the basis of your consent or in fulfilment of a contract handed over to you or to a third party in a common, machine-readable format. If you request the direct transfer of the data to another controller, this will only take place to the extent that it is technically feasible.' : 'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.' }}</p><h3>Auskunft, Löschung und Berichtigung</h3>
      <p>{{ isEnglish() ? 'Within the framework of the applicable legal provisions, you have the right at any time to free information about your stored personal data, their origin and recipient and the purpose of the data processing and, if necessary, a right to correction or deletion of this data. You can contact us at any time at the address given in the imprint if you have any further questions on the subject of personal data.' : 'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.' }}</p>
      <h3>Recht auf Einschränkung der Verarbeitung</h3>
      <p>{{ isEnglish() ? 'You have the right to request the restriction of the processing of your personal data. For this purpose, you can contact us at any time at the address given in the imprint. The right to restriction of processing exists in the following cases:' : 'Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:' }}</p>
      <ul>
        <li>{{ isEnglish() ? 'If you dispute the accuracy of your personal data stored with us, we usually need time to verify this. For the duration of the audit, you have the right to request the restriction of the processing of your personal data.' : 'Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.' }}</li>
        <li>{{ isEnglish() ? 'If the processing of your personal data was/is unlawful, you can request the restriction of data processing instead of deletion.' : 'Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.' }}</li>
        <li>{{ isEnglish() ? 'If we no longer need your personal data, but you need it to exercise, defend or assert legal claims, you have the right to request the restriction of the processing of your personal data instead of deletion.' : 'Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.' }}</li>
        <li>{{ isEnglish() ? 'If you have filed an objection pursuant to Article 21 (1) GDPR, a balance must be struck between your interests and ours. As long as it is not yet clear whose interests prevail, you have the right to request the restriction of the processing of your personal data.' : 'Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.' }}</li>
      </ul>
      <p>{{ isEnglish() ? 'If you have restricted the processing of your personal data, this data may only be processed – apart from its storage – with your consent or for the establishment, exercise or defence of legal claims or for the protection of the rights of another natural or legal person or for reasons of important public interest of the European Union or a Member State.' : 'Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.' }}</p>
      <h2>{{ isEnglish() ? '4. Data collection on this website' : '4. Datenerfassung auf dieser Website' }}</h2>
      <h3
        id="storage"
        class="pt-3"
      >
        Local Storage
      </h3>
      <p>{{ isEnglish() ? 'So that you can adapt this application to your personal needs and your use, we use the so-called local storage technology (also called "local data" and "local storage").  Data is stored locally in the cache of your browser, which continues to exist and can be read even after the browser window is closed or the program is terminated - as long as you do not delete the cache.' : 'Damit Sie diese Anwendung Ihren persönlichen Bedürfnissen und Ihrer Nutzung anpassen kannst, verwenden wir die sogenannte Local Storage Technik (auch „Lokale Daten“ und „Lokaler Speicher“ genannt). Dabei werden Daten lokal im Cache (sog. Caching) des Browsers gespeichert, die auch nach dem Schließen des Browser-Fensters oder dem Beenden des Programms – soweit der Cache nicht gelöscht wird - weiterhin bestehen und ausgelesen werden können.' }}</p>
      <p>{{ isEnglish() ? 'Local storage enables preferences when using this application to be stored on your device and used by you. The data from the local storage is used to remember your preferred language, text size, night mode (dark mode) and the last location.' :'Der Local Storage ermöglicht es, dass Präferenzen bei der Nutzung dieser Anwendung auf Ihrem Gerät gespeichert und von Ihnen genutzt werden können. Die Daten aus dem Local Storage werden verwendet, um Ihre bevorzugte Sprache, Textgröße, Nachtmodus (Dark-Mode) sowie den letzten Standort zu merken.' }}</p>
      <br>
      <button
        type="button"
        class="justify-center mb-4 rounded-md border border-transparent ui button violet text-lg font-medium text-white shadow-sm tracking-wider pt-3"
        @click="showBanner()"
      >
        {{ isEnglish() ? 'EDIT PRIVACY CONSENT' : 'ZUR DATENSCHUTZ-EINWILLIGUNG' }}
      </button>
      <br>
      <br>
      <!--h3>Cookies</!--h3>
  <p>{{ isEnglish() ? 'Our Internet pages use so-called "cookies". Cookies are small text files and do not cause any damage to your device. They are stored either temporarily on your device for the duration of a session (session cookies) or permanently (permanent cookies). Session cookies are automatically deleted at the end of your visit. Permanent cookies remain stored on your device until you delete them yourself or they are automatically deleted by your web browser.' : 'Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.' }}</p>
  <p>{{ isEnglish() ? 'In some cases, cookies from third-party companies may also be stored on your device when you enter our site (third-party cookies). These enable us or you to use certain services of the third-party company (e.g. cookies for processing payment services).' : 'Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur Abwicklung von Zahlungsdienstleistungen).' }}</p>
  <p>{{ isEnglish() ? 'Cookies have different functions. Numerous cookies are technically necessary because certain website functions would not work without them (e.g. the shopping cart function or the display of videos). Other cookies are used to evaluate user behavior or to display advertising.' : 'Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht funktionieren würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.' }}</p>
  <p>{{ isEnglish() ? 'Cookies that are necessary to carry out the electronic communication process (necessary cookies) or to provide certain functions desired by you (functional cookies, e.g. for the shopping cart function) or to optimize the website (e.g. cookies to measure the web audience) are stored on the basis of Art. 6 para. 1 lit. f GDPR, unless another legal basis is specified. The website operator has a legitimate interest in the storage of cookies for the technically error-free and optimized provision of its services. If consent to the storage of cookies has been requested, the storage of the cookies in question takes place exclusively on the basis of this consent (Art. 6 para. 1 lit. a GDPR); the consent can be revoked at any time.' : 'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur Optimierung der Webseite (z.B. Cookies zur Messung des Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.' }}</p>
  <p>{{ isEnglish() ? 'You can set your browser so that you are informed about the setting of cookies and allow cookies only in individual cases, exclude the acceptance of cookies for certain cases or in general and activate the automatic deletion of cookies when closing the browser. Disabling cookies may limit the functionality of this website.' : 'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.' }}</p>
  <p>{{ isEnglish() ? 'Insofar as cookies are used by third-party companies or for analysis purposes, we will inform you separately about this in the context of this data protection declaration and, if necessary, request your consent.' : 'Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.' }}</p> -->
      <!--h3>{{ isEnglish() ? 'Contact' : 'Kontaktformular' }}</!h3>
      <p>{{ isEnglish() ? 'If you send us enquiries via the contact form, your details from the enquiry form, including the contact details you provided there, will be stored by us for the purpose of processing the enquiry and in the event of follow-up questions. We do not pass on this data without your consent.' : 'Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.' }}</p>
      <p>{{ isEnglish() ? 'The processing of this data takes place on the basis of Art. 6 para. 1 lit. b GDPR, if your request is related to the fulfillment of a contract or is necessary for the implementation of pre-contractual measures. In all other cases, the processing is based on our legitimate interest in the effective processing of the inquiries addressed to us (Art. 6 para. 1 lit. f GDPR) or on your consent (Art. 6 para. 1 lit. a GDPR) if this has been requested.' : 'Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.' }}</p>
      <p>{{ isEnglish() ? 'The data you enter in the contact form will remain with us until you request us to delete it, revoke your consent to storage or the purpose for data storage no longer applies (e.g. after your request has been processed). Mandatory statutory provisions – in particular retention periods – remain unaffected.' : 'Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.' }}</p>
      <h3>{{ isEnglish() ? 'Comment function on this website' : 'Kommentarfunktion auf dieser Website' }}</h3>
      <p>{{ isEnglish() ? 'For the comment function on this page, in addition to your comment, information about the time of creation of the comment, your e-mail address and, if you do not post anonymously, the username you have chosen will be stored.' : 'Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte Nutzername gespeichert.' }}</p>
      <p><strong>{{ isEnglish() ? 'Storage of the IP address' : 'Speicherung der IP-Adresse' }}</strong></p>
      <p>{{ isEnglish() ? 'Our comment function stores the IP addresses of the users who write comments. Since we do not check comments on this website before they are activated, we need this data in order to be able to take action against the author in the event of legal violations such as insults or propaganda.' : 'Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare auf dieser Website nicht vor der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von Rechtsverletzungen wie Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu können.' }}</p>
      <p><strong>{{ isEnglish() ? 'Subscribe to comments' : 'Abonnieren von Kommentaren' }}</strong></p>
      <p>{{ isEnglish() ? 'As a user of the site, you can subscribe to comments after logging in. You will receive a confirmation email to check if you are the owner of the email address provided. You can unsubscribe from this function at any time via a link in the info mails. The data entered in the context of subscribing to comments will be deleted in this case; however, if you have transmitted this data to us for other purposes and elsewhere (e.g. newsletter order), this data will remain with us.' : 'Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese Funktion jederzeit über einen Link in den Info-Mails abbestellen. Die im Rahmen des Abonnierens von Kommentaren eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie diese Daten für andere Zwecke und an anderer Stelle (z.B. Newsletterbestellung) an uns übermittelt haben, verbleiben diese Daten jedoch bei uns.' }}</p>
      <p><strong>{{ isEnglish() ? 'Duration of comments' : 'Speicherdauer der Kommentare' }}</strong></p>
      <p>{{ isEnglish() ? 'The comments and the associated data (e.g. IP address) are stored and remain on this website until the commented content has been completely deleted or the comments must be deleted for legal reasons (e.g. offensive comments).' : 'Die Kommentare und die damit verbundenen Daten (z.B. IP-Adresse) werden gespeichert und verbleiben auf dieser Website, bis der kommentierte Inhalt vollständig gelöscht wurde oder die Kommentare aus rechtlichen Gründen gelöscht werden müssen (z.B. beleidigende Kommentare).' }}</p>
      <p><strong>{{ isEnglish() ? 'Legal Basis' : 'Rechtsgrundlage' }}</strong></p>
      <p>{{ isEnglish() ? 'The comments are stored on the basis of your consent (Art. 6 para. 1 lit. a GDPR). You can revoke your consent at any time. For this purpose, an informal message by e-mail to us is sufficient. The legality of the data processing operations that have already taken place remains unaffected by the revocation.' : 'Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.' }}</p -->
    </div>
  </div>
</template>
<script>
import Mixins from '@/Mixins';
  export default {
    mixins: [Mixins],
    methods: {
      isEnglish() {
          return this.$currentLanguage.value == 'en';   
      },
      showBanner() {
          this.$showPrivacyBanner.value = true;
      }
    }
  }
  
</script>
