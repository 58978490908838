<template>
  <div class="max-w-3xl ml-1 mb-1">
    <form
      v-if="!isSearchFormCollapsed"
      class="ui segment large form"
    >
      <div class="field">
        <label for="login">Login</label>
        <input
          id="login"
          v-model="login"
          name="login"
          type="text"
          autocomplete="email"
        >
      </div>
      <div class="field">
        <label for="password">Password</label>
        <input
          id="password"
          v-model="password"
          name="password"
          type="password"
          autocomplete="current-password"
        >
      </div>
      <div class="field">
        <div class="ui right icon input large">
          <button
            :disabled="login.length === 0 || password.length === 0 || isLoading"
            class="ui button violet"
            type="button"
            autofocus
            @click="performLogin()"
          >
            Login
          </button>
        </div>
      </div>
    </form>
  </div>
  <div>  
    <div
      v-if="isLoading"
      class="row col"
    >
      <p>Loading...</p>
    </div>
  
    <div
      v-else-if="hasError"
      class="row col"
    >
      <div
        class="alert alert-danger"
        role="alert"
      >
        {{ error }}
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: "Login",
    data() {
      return {
        login: "",
        password: ""
      };
    },
    computed: {
      isLoading() {
        return this.$store.getters["security/isLoading"];
      },
      hasError() {
        return this.$store.getters["security/hasError"];
      },
      error() {
        return this.$store.getters["security/error"];
      }
    },
    created() {
      let redirect = this.$route.query.redirect;
  
      if (this.$store.getters["security/isAuthenticated"]) {
        if (typeof redirect !== "undefined") {
          this.$router.push({path: redirect});
        } else {
          this.$router.push({path: "/"});
        }
      }
    },
    methods: {
      async performLogin() {
        let payload = {login: this.$data.login, password: this.$data.password},
          redirect = this.$route.query.redirect;
  
        await this.$store.dispatch("security/login", payload);
        if (!this.$store.getters["security/hasError"]) {
          if (typeof redirect !== "undefined") {
            this.$router.push({path: redirect});
          } else {
            this.$router.push({path: "/"});
          }
        }
      }
    }
  }
  </script>